import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  TeamOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

const WelComeCard = ({ data }) => {

  const [user, setUser] = useState({});

  const getUserFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  };

  useEffect(() => {
    setUser(getUserFromLocalStorage());
  }, [])

  console.log({superAdminData: data});
  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h1 className="gx-mb-3">Welcome {user?.name || 'Super Admin'}!</h1>
      {/* <p className="gx-fs-sm gx-text-uppercase">You Have</p> */}
      <ul className="gx-list-group">
        <li>
          <AppstoreOutlined />

          <span>{data?.streams} Streams</span>
        </li>
        <li>
          <TeamOutlined />
          <span>{data?.departments} Departments</span>
        </li>
        <li>
          <HomeOutlined />
          <span>{data?.divisions} Classrooms</span>
        </li>
        <li>
          <UsergroupAddOutlined />
          <span>{data?.students} Students</span>
        </li>
      </ul>
    </div>
  );
};

export default WelComeCard;
