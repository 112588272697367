import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import { httpClient } from "../util/Api";
import { storedUser } from "../util/storage";
import { useContext } from "react";
import { AuthContext } from "../context/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const query = new URLSearchParams(useLocation().search);
  const passwordResetToken = query.get("token");

  const { authUser, setAuthUser } = useContext(AuthContext);

  const handleUpdatePassword = async (values) => {
    try {
      // console.log(passwordResetToken, "token");
      const res = await httpClient.post("/auth/changePassword", {
        newPassword: values.password,
        token: passwordResetToken,
      });

      if (res.status === 200) {
        message.success("Password updated successfully");
        history.push("/signin");
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (storedUser() !== null) {
      history.push("/");
    }
  }, []);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>

            <div className="gx-app-logo-wid">
              <h1>Update Password</h1>
              <img src="/SignIn.png" alt="SignIn" />
              <p>
                <Link to="/signin" style={{ color: "white" }}>
                  Sign In
                </Link>
              </p>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              form={form}
              name="updatePasswordForm"
              layout="vertical"
              onFinish={handleUpdatePassword}
              style={{ marginTop: "10%" }}
            >
              <Form.Item
                label="New Psassword"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    pattern: new RegExp(
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                    ),
                    message:
                      "Password must contain at least 8 characters, including UPPER/lowercase and numbers.",
                  },
                ]}
              >
                <Input.Password placeholder="Enter your new password" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: "confirm password" },

                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="confirm password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
