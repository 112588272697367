import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import Dashboard from "../../routes/dashboard";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import {
  BarChartOutlined,
  BookOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CiCircleTwoTone,
  ClusterOutlined,
  ContainerOutlined,
  EditOutlined,
  FileProtectOutlined,
  GroupOutlined,
  MenuUnfoldOutlined,
  SolutionOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/auth";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HodNav = (props) => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);
  const history = useHistory();
  const { authUser } = useContext(AuthContext);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      {authUser.kycStatus === "Approved" && (
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={!props.isSidebar ? "lite" : "dark"}
          mode={props.isSidebar ? "inline" : "horizontal"}
          style={{ width: 700 }}
        >
          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="main"
            title={"Dashboard"}
            onTitleClick={() => history.push("/hod-routes/dashboard")}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="academics"
            title={"Academics"}
          >
            <Menu.Item key="in-built-apps/todo">
              <Link to="/hod-routes/division/table">
                <GroupOutlined />
                Divisions
              </Link>
            </Menu.Item>
            <Menu.Item key="in-built-apps/todo">
              <Link to="/hod-routes/attendance/table">
                <SolutionOutlined />
                Attendances
              </Link>
            </Menu.Item>

            <Menu.Item key="in-built-apps/todo">
              <Link to="/hod-routes/assignment/table">
                <SolutionOutlined />
                Assignments
              </Link>
            </Menu.Item>

            <Menu.Item key="in-built-apps/todo">
              <Link to="/hod-routes/placement/table">
                <SolutionOutlined />
                Placements
              </Link>
            </Menu.Item>

            <Menu.Item key="in-built-apps/todo">
              <Link to="/hod-routes/exams/table">
                <SolutionOutlined />
                Exams
              </Link>
            </Menu.Item>

            <Menu.Item key="in-built-apps/todo">
              <Link to="/hod-routes/result/table">
                <SolutionOutlined />
                Result
              </Link>
            </Menu.Item>

            {/* <Menu.Item key="in-built-apps/todo">
          <Link to="/hod-routes/assessment/table">
            <SolutionOutlined />
            Assessments
          </Link>
        </Menu.Item> */}
          </SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="profiler"
            title={"Profiler"}
          >
            <Menu.Item key="extra-components/editor/ck">
              <Link to="/hod-routes/student/table">
                <UserOutlined />
                Students
              </Link>
            </Menu.Item>

            <Menu.Item key="extra-components/editor/ck">
              <Link to="/hod-routes/faculty/table">
                <UserOutlined />
                Staffs
              </Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="news"
            title={"News & Events"}
            onTitleClick={() => history.push(`/hod-routes/news/table`)}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="assessment"
            title={"Assessment"}
            onTitleClick={() => history.push(`/hod-routes/assessment/table`)}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="reports"
            title={"Reports"}
          >
            {/* <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/hod-routes/reports/admission">
            <UserOutlined />
            Admission Report
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/hod-routes/reports/fees">
            <UserOutlined />
            Fees Report
          </Link>
        </Menu.Item> */}

            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/hod-routes/reports/student">
                <UserOutlined />
                Students Report
              </Link>
            </Menu.Item>

            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/hod-routes/reports/staff">
                <UserOutlined />
                Staffs Report
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      )}
    </>
  );
};
export default HodNav;
