import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HODRoutes = ({ match }) => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/hod-routes/profile/form"
    ) {
      history.replace("/hod-routes/profile/form");
    }

    if (authUser.isProfileCompleted && authUser.kycStatus !== "Approved") {
      history.replace("/hod-routes/myProfile/kyc");
    }
  }, []);

  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        exact
        path={`${match.url}/profile/form`}
        component={asyncComponent(() => import("../common/facultyKYC"))}
      />
      <Route
        path={`${match.url}/myProfile/kyc`}
        component={asyncComponent(() => import("../common/kycStatusPage"))}
      />

      {/*Student*/}
      <Route
        path={`${match.url}/student/table`}
        component={asyncComponent(() => import("./student/table"))}
      />
      <Route
        path={`${match.url}/student/details`}
        component={asyncComponent(() => import("./student/details"))}
      />

      {/* Divisions */}
      <Route
        path={`${match.url}/division/table`}
        component={asyncComponent(() => import("./division/table"))}
      />
      <Route
        path={`${match.url}/division/create`}
        component={asyncComponent(() => import("./division/create"))}
      />

      <Route
        path={`${match.url}/division/edit`}
        component={asyncComponent(() => import("./division/create"))}
      />

      {/* Assignment*/}
      <Route
        path={`${match.url}/assignment/table`}
        component={asyncComponent(() => import("./assignment/table"))}
      />
      <Route
        path={`${match.url}/assignment/create`}
        component={asyncComponent(() => import("./assignment/form"))}
      />
      <Route
        path={`${match.url}/assignment/edit`}
        component={asyncComponent(() => import("./assignment/form"))}
      />
      <Route
        path={`${match.url}/assignment/view`}
        component={asyncComponent(() => import("./assignment/view"))}
      />

      {/* Exams */}
      <Route
        path={`${match.url}/exams/table`}
        component={asyncComponent(() => import("./exams/table"))}
      />
      <Route
        path={`${match.url}/exams/create`}
        component={asyncComponent(() => import("./exams/create"))}
      />
      <Route
        path={`${match.url}/exams/edit`}
        component={asyncComponent(() => import("./exams/create"))}
      />

      {/* Attendance */}
      <Route
        path={`${match.url}/attendance/table`}
        component={asyncComponent(() => import("./attendance/table"))}
      />
      <Route
        path={`${match.url}/attendance/view`}
        component={asyncComponent(() => import("./attendance/view"))}
      />
      <Route
        path={`${match.url}/attendance/create`}
        component={asyncComponent(() => import("./attendance/form"))}
      />
      <Route
        path={`${match.url}/attendance/edit`}
        component={asyncComponent(() => import("./attendance/form"))}
      />

      {/* Reports */}
      <Route
        path={`${match.url}/reports/student`}
        component={asyncComponent(() => import("./reports/student"))}
      />
      <Route
        path={`${match.url}/reports/staff`}
        component={asyncComponent(() => import("./reports/staff"))}
      />
      <Route
        path={`${match.url}/reports/admission`}
        component={asyncComponent(() => import("./reports/admission"))}
      />
      <Route
        path={`${match.url}/reports/fees`}
        component={asyncComponent(() => import("./reports/fees"))}
      />

      {/* Placement */}
      <Route
        path={`${match.url}/placement/table`}
        component={asyncComponent(() => import("./placement/table"))}
      />
      <Route
        path={`${match.url}/placement/edit`}
        component={asyncComponent(() => import("./placement/create"))}
      />  
      <Route
        path={`${match.url}/placement/create`}
        component={asyncComponent(() => import("./placement/create"))}
      />  

      {/* News */}
      <Route
        path={`${match.url}/news/table`}
        component={asyncComponent(() => import("../common/news/table"))}
      />
      {/* <Route path={`${match.url}/news/create`} component={asyncComponent(()=> import('./news/create'))}/>
        <Route path={`${match.url}/news/edit/:newsId`} component={asyncComponent(() => import('./news/edit'))} /> */}

      {/* Event */}
      <Route
        path={`${match.url}/event/table`}
        component={asyncComponent(() => import("./event/table"))}
      />
      <Route
        path={`${match.url}/event/create`}
        component={asyncComponent(() => import("./event/create"))}
      />
      <Route
        path={`${match.url}/event/edit/:eventId`}
        component={asyncComponent(() => import("./event/edit"))}
      />

      {/* Faculty */}
      <Route
        path={`${match.url}/faculty/table`}
        component={asyncComponent(() => import("./faculty/table"))}
      />
      <Route
        path={`${match.url}/faculty/details`}
        component={asyncComponent(() => import("./faculty/details"))}
      />
      <Route
        path={`${match.url}/faculty/assign`}
        component={asyncComponent(() => import("./faculty/assign"))}
      />
      <Route
        path={`${match.url}/faculty/roles`}
        component={asyncComponent(() => import("./faculty/roles"))}
      />

      {/* Assessment */}
      <Route
        path={`${match.url}/faculty/assessment/edit`}
        component={asyncComponent(() =>
          import("../common/assessment/editAssessment")
        )}
      />
      <Route
        path={`${match.url}/assessment/form`}
        component={asyncComponent(() => import("../common/assessment/form"))}
      />
      <Route
        path={`${match.url}/assessment/table`}
        component={asyncComponent(() => import("../common/assessment/table"))}
      />

      {/* Result */}
      <Route
        path={`${match.url}/result/table`}
        component={asyncComponent(() => import("./result/table"))}
      />
      <Route
        path={`${match.url}/result/create`}
        component={asyncComponent(() => import("./result/create"))}
      />
      <Route
        path={`${match.url}/result/view`}
        component={asyncComponent(() => import("./result/view"))}
      />
      <Route
        path={`${match.url}/result/edit`}
        component={asyncComponent(() => import("./result/create"))}
      />
      {/* My Profile */}
      <Route
        path={`${match.url}/myProfile/update`}
        component={asyncComponent(() => import("../common/myProfile/edit"))}
      />
      <Route
        path={`${match.url}/myProfile/view`}
        component={asyncComponent(() => import("../common/myProfile/view"))}
      />
    </Switch>
  );
};

export default HODRoutes;
