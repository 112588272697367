import React from "react";

import Metrics from "components/Metrics";
import { Avatar, Col, Row } from "antd";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const userImageList = [
  {
    id: 1,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    image: "https://via.placeholder.com/150",
    name: "Mila Alba",
    rating: "5.0",
    deals: "27 Deals",
  },
];

const data1 = [
  { name: "Done", value: 666 },
  { name: "Pending", value: 0 },
];
const data2 = [
  { name: "Done", value: 240 },
  { name: "Pending", value: 125 },
];
const data3 = [
  { name: "Done", value: 870 },
  { name: "Pending", value: 198 },
];
const data4 = [
  { name: "Done", value: 465 },
  { name: "Pending", value: 204 },
];

const COLORS = ["#5797fc", "#FA8C16", "#f5222d", "#d9d9d9"];

const NewCustomers = ({data}) => {
  return (
    <Metrics title="Registration Status">
      <div className="gx-customers">
        <ul className="gx-list-inline gx-customers-list gx-mb-0">
          {/* <li className="gx-mb-2"> */}
          <Row>
            {
              data?.map(d =>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <ResponsiveContainer width="100%" height={40}>
                    <PieChart>
                      <Tooltip
                        wrapperStyle={{ zIndex: 1000, background: "#FFF" }}
                      />
                      <text
                        x="50%"
                        className="p"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {d?.value}
                      </text>
                      <Pie
                        data={data1}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        innerRadius={14}
                        outerRadius={21}
                        fill="#8884d8"
                      >
                        {data1.map((entry, index) => (
                          <Cell key={index} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <p style={{ textAlign: "center", marginTop: "3px" }}>{d?.name}</p>
                </Col>
              )
            }

            {/* <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <ResponsiveContainer width="100%" height={40}>
                <PieChart>
                  <Tooltip
                    wrapperStyle={{ zIndex: 1000, background: "#FFF" }}
                  />
                  <text
                    x="50%"
                    className="p"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    60
                  </text>
                  <Pie
                    data={data2}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={14}
                    outerRadius={21}
                    fill="#8884d8"
                  >
                    {data2.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <p style={{ textAlign: "center", marginTop: "3px" }}>Document</p>
            </Col>

            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <ResponsiveContainer width="100%" height={40}>
                <PieChart>
                  <Tooltip
                    wrapperStyle={{ zIndex: 1000, background: "#FFF" }}
                  />
                  <text
                    x="50%"
                    className="p"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    81
                  </text>
                  <Pie
                    data={data3}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={14}
                    outerRadius={21}
                    fill="#8884d8"
                  >
                    {data3.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <p style={{ textAlign: "center", marginTop: "3px" }}>Fee</p>
            </Col> */}

            {/* <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <ResponsiveContainer width="100%" height={40}>
                <PieChart>
                  <Tooltip
                    wrapperStyle={{ zIndex: 1000, background: "#FFF" }}
                  />
                  <text
                    x="50%"
                    className="p"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    72
                  </text>
                  <Pie
                    data={data4}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={14}
                    outerRadius={21}
                    fill="#8884d8"
                  >
                    {data4.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <p style={{ textAlign: "center", marginTop: "3px" }}>Admission</p>
            </Col> */}
          </Row>
          {/* </li> */}
          {/* {userImageList.map((user, index) =>
            <li className="gx-mb-2" key={index}>
              <Avatar src={user.image}/>
            </li>
          )
          } */}
        </ul>
      </div>
    </Metrics>
  );
};

export default NewCustomers;
