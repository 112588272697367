import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const JrFacultyRoutes = ({ match }) => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/junior-faculty-routes/profile/form"
    ) {
      history.replace("/junior-faculty-routes/profile/form");
    }

    if (authUser.isProfileCompleted && authUser.kycStatus !== "Approved") {
      history.replace("/junior-faculty-routes/myProfile/kyc");
    }
  }, []);

  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./dashboard"))}
      />

      <Route
        exact
        path={`${match.url}/profile/form`}
        component={asyncComponent(() => import("../common/facultyKYC"))}
      />

      {/* My Profile */}
      <Route
        path={`${match.url}/myProfile/kyc`}
        component={asyncComponent(() => import("../common/kycStatusPage"))}
      />
      <Route
        path={`${match.url}/myProfile/update`}
        component={asyncComponent(() => import("../common/myProfile/edit"))}
      />

      <Route
        path={`${match.url}/myProfile/view`}
        component={asyncComponent(() => import("../common/myProfile/view"))}
      />

      {/* Student */}
      <Route
        path={`${match.url}/student/table`}
        component={asyncComponent(() => import("./student/table"))}
      />
      <Route
        path={`${match.url}/student/detail`}
        component={asyncComponent(() => import("./student/details"))}
      />

      {/* News */}
      <Route
        path={`${match.url}/news/table`}
        component={asyncComponent(() => import("../common/news/table"))}
      />
      {/* <Route
      path={`${match.url}/news/create`}
      component={asyncComponent(() => import("./news/create"))}
    />
    <Route
      path={`${match.url}/news/edit/:newsId`}
      component={asyncComponent(() => import("./news/edit"))}
    /> */}

      {/* Assessment */}
      <Route
        path={`${match.url}/assessment/table`}
        component={asyncComponent(() => import("./assessment/table"))}
      />

      {/* Events */}
      <Route
        path={`${match.url}/event/table`}
        component={asyncComponent(() => import("./event/table"))}
      />
      <Route
        path={`${match.url}/event/create`}
        component={asyncComponent(() => import("./event/create"))}
      />
      <Route
        path={`${match.url}/event/edit/:eventId`}
        component={asyncComponent(() => import("./event/edit"))}
      />

      {/* Attendance */}
      <Route
        path={`${match.url}/attendance/create`}
        component={asyncComponent(() => import("./attendance/form"))}
      />
      <Route
        path={`${match.url}/attendance/table`}
        component={asyncComponent(() => import("./attendance/table"))}
      />
      <Route
        path={`${match.url}/attendance/edit`}
        component={asyncComponent(() => import("./attendance/form"))}
      />
      <Route
        path={`${match.url}/attendance/view`}
        component={asyncComponent(() => import("./attendance/view"))}
      />

      {/* Assignment */}
      <Route
        path={`${match.url}/assignment/table`}
        component={asyncComponent(() => import("./assignment/table"))}
      />
      <Route
        path={`${match.url}/assignment/create`}
        component={asyncComponent(() => import("./assignment/form"))}
      />
      <Route
        path={`${match.url}/assignment/edit`}
        component={asyncComponent(() => import("./assignment/form"))}
      />
      <Route
        path={`${match.url}/assignment/view`}
        component={asyncComponent(() => import("./assignment/view"))}
      />

      {/* Result */}
      <Route
        path={`${match.url}/result/table`}
        component={asyncComponent(() => import("./result/table"))}
      />
      <Route
        path={`${match.url}/result/create`}
        component={asyncComponent(() => import("./result/create"))}
      />
      <Route
        path={`${match.url}/result/edit`}
        component={asyncComponent(() => import("./result/create"))}
      />
      <Route
        path={`${match.url}/result/view`}
        component={asyncComponent(() => import("./result/view"))}
      />
    </Switch>
  );
};

export default JrFacultyRoutes;
