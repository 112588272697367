import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../../context/auth";

const UserInfo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser, setAuthUser } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const handleMyAccount = () => {
    setVisible(false);
    history.push(`${props.myProfileURL}/myProfile/view`);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={handleMyAccount}>My Profile</li>
      <li onClick={() => handleLogout()}>Logout</li>
    </ul>
  );

  const handleLogout = async () => {
    setAuthUser(null);
    localStorage.clear();
    // Clear all caches
    if ("caches" in window) {
      const names = await window.caches.keys();
      await Promise.all(names.map((name) => window.caches.delete(name)));
    }
    window.location.reload();
  };
  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
      visible={visible}
    >
      <Avatar
        src={userInfo?.profilePhoto || "https://via.placeholder.com/150"}
        className="gx-avatar gx-pointer"
        alt=""
        onClick={() => setVisible(!visible)}
      />
    </Popover>
  );
};

export default UserInfo;
