import React from "react";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  CheckCircleOutlined,
  BarChartOutlined,
  FileProtectOutlined,
  UserAddOutlined,
  TransactionOutlined,
  BookOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const SubMenu = Menu.SubMenu;

const StudentNav = (props) => {
  const history = useHistory();
  const registrationStatus = props.isAdmissionApproved;
  console.log(props);

  return (
    <Menu
      theme={!props.isSidebar ? "lite" : "dark"}
      mode={props.isSidebar ? "inline" : "horizontal"}
      style={{ width: 700 }}
    >
      {registrationStatus && (
        <SubMenu
          key="main"
          title={"Dashboard"}
          onTitleClick={() => history.push("/student-routes/dashboard")}
        />
      )}
      {registrationStatus && (
        <SubMenu key="academics" title="Academics">
          <Menu.Item key="assignment">
            <Link to="/student-routes/assignment/table">
              <CheckCircleOutlined /> Assignments
            </Link>
          </Menu.Item>
          <Menu.Item key="attendance">
            <Link to="/student-routes/attendance">
              <CheckCircleOutlined />
              Attendances
            </Link>
          </Menu.Item>

          <Menu.Item key="result">
            <Link to="/student-routes/result">
              <BarChartOutlined />
              Results
            </Link>
          </Menu.Item>

          <Menu.Item key="certificate">
            <Link to="/student-routes/myProfile/certificate/table">
              <FileProtectOutlined />
              Certificates
            </Link>
          </Menu.Item>
          <Menu.Item key="palcement">
            <Link to="/student-routes/placement">
              <FileProtectOutlined />
              Placement
            </Link>
          </Menu.Item>
        </SubMenu>
      )}

      <SubMenu key="admission" title="Admission">
        <Menu.Item key="admission">
          <Link to="/student-routes/courses/table">
            <UserAddOutlined />
            Available Courses
          </Link>
        </Menu.Item>

        <Menu.Item key="applied">
          <Link to="/student-routes/admission/table">
            <UserAddOutlined />
            Applied Courses
          </Link>
        </Menu.Item>

        {/* <Menu.Item key="components/general/button">
           <Link to="/student-routes/myProfile/personal/information">
             <EditOutlined />
             My Profile
           </Link>
         </Menu.Item> */}

        <Menu.Item key="documents">
          <Link to="/student-routes/myProfile/documents/table">
            <TransactionOutlined />
            Documents
          </Link>
        </Menu.Item>

        <Menu.Item key="fees">
          <Link to="/student-routes/payments">
            <TransactionOutlined />
            Payments
          </Link>
        </Menu.Item>
      </SubMenu>

      {registrationStatus && (
        <SubMenu key="library" title="Library">
          <Menu.Item key="book">
            <Link to="/student-routes/library/booktable">
              <BookOutlined />
              Books
            </Link>
          </Menu.Item>

          <Menu.Item key="in/outRegister">
            <Link to="/student-routes/library/inOutRegister">
              <FormOutlined />
              In/Out Register
            </Link>
          </Menu.Item>

          <Menu.Item key="bookRequest">
            <Link to="/student-routes/library/bookRequest">
              <QuestionCircleOutlined />
              Book Requests
            </Link>
          </Menu.Item>
        </SubMenu>
      )}

      <SubMenu
        key="news"
        title="News & Events"
        onTitleClick={() => history.push("/student-routes/news")}
      />
    </Menu>
  );
};

export default StudentNav;
