import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import {
  BookOutlined,
  CalendarOutlined,
  ClusterOutlined,
  EditOutlined,
  FileProtectOutlined,
  GroupOutlined,
  MenuUnfoldOutlined,
  SolutionOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
const SubMenu = Menu.SubMenu;

const SuperAdminNav = (props) => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);
  const history = useHistory();

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme={!props.isSidebar ? "lite" : "dark"}
      mode={props.isSidebar ? "inline" : "horizontal"}
    >
      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="main"
        title={"Dashboard"}
        onTitleClick={() => history.push("/super-admin-routes/dashboard")}
      ></SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="academics"
        title={"Academics"}
      >
        <Menu.Item key="in-built-apps/todo">
          <Link to="/super-admin-routes/attendance/table">
            <FileProtectOutlined />
            Attendances
          </Link>
        </Menu.Item>

        <Menu.Item key="in-built-apps/todo">
          <Link to="/super-admin-routes/result/table">
            <FileProtectOutlined />
            Results
          </Link>
        </Menu.Item>

        <Menu.Item key="in-built-apps/todo">
          <Link to="/super-admin-routes/placement/table">
            <SolutionOutlined />
            Placements
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="admission"
        title={"Admissions"}
      >
        <Menu.Item key="in-built-apps/mail">
          <Link to="/super-admin-routes/admission/table">
            <UserAddOutlined />
            Admissions
          </Link>
        </Menu.Item>

        <Menu.Item key="components/general/button">
          <Link to="/super-admin-routes/registration/table">
            <EditOutlined />
            Registrations
          </Link>
        </Menu.Item>

        <Menu.Item key="components/general/button">
          <Link to="/super-admin-routes/payment/table">
            <TransactionOutlined />
            Payments
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="profiler"
        title={"Profiler"}
      >
        <Menu.Item key="extra-components/editor/ck">
          <Link to="/super-admin-routes/student/table">
            <UserOutlined />
            Students
          </Link>
        </Menu.Item>

        <Menu.Item key="extra-components/editor/ck">
          <Link to="/super-admin-routes/faculty/table">
            <UserOutlined />
            Staffs
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="library"
        title={"Library"}
        onTitleClick={() => history.push(`/super-admin-routes/book/table`)}
      ></SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="news"
        title={"News & Events"}
        onTitleClick={() => history.push(`/super-admin-routes/news/table`)}
      ></SubMenu>
      {/*
      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="event"
        title={"Events"}
        onTitleClick={() => history.push(`/super-admin-routes/event/table`)}
      ></SubMenu> */}

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="reports"
        title={"Reports"}
      >
        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/faculty/assessment/table">
            <GroupOutlined />
            Assessments Report
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/reports/admission">
            <UserAddOutlined />
            Admissions Report
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/reports/fees">
            <TransactionOutlined />
            Fees Report
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="settings"
        title={"Settings"}
      >
        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/designation/table">
            <UserOutlined />
            Create Designation
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/faculty/create">
            <UserOutlined />
            Create Staff/Faculty
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/department/table">
            <ClusterOutlined />
            Create Departments
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/academicYear/table">
            <CalendarOutlined />
            Create Academic Year
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/syllabus/table">
            <MenuUnfoldOutlined />
            Create Syllabus
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/course/table">
            <BookOutlined />
            Create Stream/Courses
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/division/table">
            <GroupOutlined />
            Create Divisions
          </Link>
        </Menu.Item>

        <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/super-admin-routes/batch/table">
            <ClusterOutlined />
            Create Batch
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

SuperAdminNav.propTypes = {};

export default SuperAdminNav;
