import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import Dashboard from "../../routes/dashboard";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import {
  EditOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserOutlined,
  HomeOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/auth";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const PrincipleDirectorNav = (props) => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);
  const history = useHistory();
  const { authUser } = useContext(AuthContext);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      {authUser.kycStatus === "Approved" && (
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={!props.isSidebar ? "lite" : "dark"}
          mode={props.isSidebar ? "inline" : "horizontal"}
        >
          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="main"
            title={"Dashboard"}
            onTitleClick={() =>
              history.push("/principle-director-routes/dashboard")
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="admission"
            title={"Admission"}
          >
            <Menu.Item key="in-built-apps/mail">
              <Link to="/principle-director-routes/admission/table">
                <UserAddOutlined />
                Admissions
              </Link>
            </Menu.Item>

            <Menu.Item key="components/general/button">
              <Link to="/principle-director-routes/registration/table">
                <EditOutlined />
                Registration
              </Link>
            </Menu.Item>

            <Menu.Item key="components/general/button">
              <Link to="/principle-director-routes/payment/table">
                <TransactionOutlined />
                Payment
              </Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="academics"
            title={"Academics"}
          >
            <Menu.Item key="attendance">
              <Link to="/principle-director-routes/attendance/table">
                <UserAddOutlined />
                Attendance
              </Link>
            </Menu.Item>
            <Menu.Item key="in-built-apps/mail">
              <Link to="/principle-director-routes/certificate/table">
                <UserAddOutlined />
                Certificates
              </Link>
            </Menu.Item>

            <Menu.Item key="in-built-apps/mail">
              <Link to="/principle-director-routes/placement/table">
                <UserAddOutlined />
                Placements
              </Link>
            </Menu.Item>
            <Menu.Item key="in-built-apps/mail">
              <Link to="/principle-director-routes/result/table">
                <UserAddOutlined />
                Result
              </Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="profiler"
            title={"Profiler"}
          >
            <Menu.Item key="extra-components/editor/ck">
              <Link to="/principle-director-routes/student/table">
                <UserOutlined />
                Students
              </Link>
            </Menu.Item>

            <Menu.Item key="extra-components/editor/ck">
              <Link to="/principle-director-routes/faculty/table">
                <UserOutlined />
                Staffs
              </Link>
            </Menu.Item>
          </SubMenu>

          {/* <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="result"
        title={"Result"}
        onTitleClick={() =>
          history.push("/principle-director-routes/result/table")
        }
      ></SubMenu> */}

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="reports"
            title={"Reports"}
          >
            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/principle-director-routes/assessment/table">
                <GroupOutlined />
                Assessments Report
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/principle-director-routes/reports/admission">
            <UserAddOutlined />
            Admissions Report
          </Link>
        </Menu.Item> */}

            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/principle-director-routes/reports/fees">
                <TransactionOutlined />
                Fees Report
              </Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="newsEvents"
            title={"News & Events"}
            onTitleClick={() =>
              history.push(`/principle-director-routes/news/table`)
            }
          ></SubMenu>

          {/* <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="event"
        title={"Event"}
        onTitleClick={() =>
          history.push(`/principle-director-routes/event/table`)
        }
      ></SubMenu> */}

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="Assessment"
            title={"Assessment"}
            onTitleClick={() =>
              history.push(`/principle-director-routes/assessment/table`)
            }
          ></SubMenu>
        </Menu>
      )}
    </>
  );
};

export default PrincipleDirectorNav;
