import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import UpdatePassword from "../UpdatePassword";
import { AuthContext } from "../../context/auth";
import Dashboard from "../../routes/dashboard";
import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import SuperAdminApp from "./superAdminApp";
import StudentApp from "./studentApp";
import { storedUser } from "../../util/storage";
import { httpClient } from "../../util/Api";
// import Dashboard from "../../routes/dashboard";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

let styleSheetLink = document.createElement("link");
styleSheetLink.type = "text/css";
styleSheetLink.rel = "stylesheet";
document.body.appendChild(styleSheetLink);

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeColor = useSelector(({ settings }) => settings.themeColor);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);

  const dispatch = useDispatch();
  // const { authUser, initURL } = useSelector(({ auth }) => auth);
  const { initURL } = useSelector(({ auth }) => auth);
  const [authUser, setAuthUser] = useState(storedUser());

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      styleSheetLink.href = "/css/dark_theme.min.css";
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
  }, [location.search, dispatch, initURL, location.pathname]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const getUserData = async () => {
    try {
      const userData = await httpClient.get(`/user/${authUser._id}`);

      if (userData.data.isBlocked === true) {
        localStorage.clear();
        window.location.reload();
        return null;
      }
      setAuthUser(userData.data);
      localStorage.setItem("user", JSON.stringify(userData.data));
      return userData.data;
    } catch (error) {
      console.error("Error getting user data:", error);
      return null;
    }
  };

  useEffect(() => {
    const handleRefresh = () => {
      // Your logic here
      getUserData();
    };

    window.addEventListener("load", handleRefresh);

    // Cleanup function
    return () => {
      window.removeEventListener("load", handleRefresh);
    };
  }, []);

  const redirectAccess = () => {
    switch (authUser?.primaryRole?.name) {
      case "Super Admin":
        history.push("/super-admin-routes/dashboard");
        break;
      case "Admin":
        history.push("/admin-routes/dashboard");
        break;
      case "HOD":
        history.push("/hod-routes/dashboard");
        break;
      case "Junior Faculty":
        history.push("/junior-faculty-routes/dashboard");
        break;
      case "Senior Faculty":
        history.push("/senior-faculty-routes/dashboard");
        break;
      case "Principal":
        history.push("/principle-director-routes/dashboard");
        break;
      case "Librarian":
        history.push("/library-routes/dashboard");
        break;
      case "Student":
        history.push("/student-routes/dashboard");
        break;
      default:
        history.push("/student-routes/dashboard");
        break;
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      if (authUser === null) {
        history.push("/signin");
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        redirectAccess();
      } else {
        history.push(initURL);
      }
    } else {
      const roleRoute = location.pathname.split("/")[1];
      if (
        !(
          (roleRoute === "super-admin-routes" &&
            authUser?.primaryRole?.name === "Super Admin") ||
          (roleRoute === "admin-routes" &&
            authUser?.primaryRole?.name === "Admin") ||
          (roleRoute === "hod-routes" &&
            authUser?.primaryRole?.name === "HOD") ||
          (roleRoute === "junior-faculty-routes" &&
            authUser?.primaryRole?.name === "Junior Faculty") ||
          (roleRoute === "senior-faculty-routes" &&
            authUser?.primaryRole?.name === "Senior Faculty") ||
          (roleRoute === "principle-director-routes" &&
            authUser?.primaryRole?.name === "Principal") ||
          (roleRoute === "library-routes" &&
            authUser?.primaryRole?.name === "Librarian") ||
          (roleRoute === "student-routes" &&
            (!authUser?.primaryRole ||
              authUser?.primaryRole?.name === "Student"))
        )
      ) {
        authUser ? redirectAccess() : console.log("continue");
      }
    }
  }, [initURL, location, history, authUser?.primaryRole?.name]);

  const currentAppLocale = AppLocale[locale.locale];

  const getAccessApp = () => {
    const splittedRoute = location.pathname.split("/");
    if (splittedRoute.length > 1) {
      if (splittedRoute[1] === "super-admin-routes") {
        return SuperAdminApp;
      }
      if (splittedRoute[1] === "student-routes") {
        return StudentApp;
      }
    }
  };

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      <ConfigProvider
        locale={currentAppLocale.antd}
        direction={isDirectionRTL ? "rtl" : "ltr"}
      >
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/update-password" component={UpdatePassword} />

            {/* <Route exact path="/dashboard" component={Dashboard} /> */}
            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              location={location}
              component={StudentApp}
            />
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    </AuthContext.Provider>
  );
};

export default memo(App);
