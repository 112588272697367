import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import Dashboard from "../../routes/dashboard";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import {
  BarChartOutlined,
  BookOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CiCircleTwoTone,
  ClusterOutlined,
  ContainerOutlined,
  EditOutlined,
  FileProtectOutlined,
  GroupOutlined,
  MenuUnfoldOutlined,
  SolutionOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/auth";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const AdminNav = (props) => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);
  const history = useHistory();
  const { authUser } = useContext(AuthContext);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      {authUser.kycStatus === "Approved" && (
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={!props.isSidebar ? "lite" : "dark"}
          mode={props.isSidebar ? "inline" : "horizontal"}
        >
          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="main"
            title={"Dashboard"}
            onTitleClick={() => history.push("/admin-routes/dashboard")}
          ></SubMenu>

          {/* <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} key="assessment" title={'Assessment'} onTitleClick={
        () => history.push('/admin-routes/assessment/table')
      }>
      </SubMenu>

      <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} key="assignment" title={'Assignment'} onTitleClick={() => history.push(`/admin-routes/assignment/table`)}>
      </SubMenu> */}

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="registration"
            title={"Registrations"}
            onTitleClick={() =>
              history.push(`/admin-routes/registration/table`)
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="admissions"
            title={"Admissions"}
            onTitleClick={() => history.push(`/admin-routes/admission/table`)}
          ></SubMenu>

          {/* <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} key="attendance" title={'Attendance'} onTitleClick={() => history.push(`/admin-routes/attendance/table`)}>
      </SubMenu> */}

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="event"
            title={"Certificates"}
            onTitleClick={() => history.push(`/admin-routes/certificate/table`)}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="placement"
            title={"Placement"}
            onTitleClick={() => history.push(`/admin-routes/placement/table`)}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="student"
            title={"Students"}
            onTitleClick={() => history.push(`/admin-routes/student/table`)}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="payment"
            title={"Payments"}
            onTitleClick={() => history.push(`/admin-routes/payment/table`)}
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="reports"
            title={"Reports"}
          >
            {/* <Menu.Item key="custom-views/extras/testimonials">
          <Link to="/admin-routes/reports/student">
            <UserOutlined />
            Students Report
          </Link>
        </Menu.Item> */}

            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/admin-routes/faculty/assessment/table">
                <GroupOutlined />
                Assessments Report
              </Link>
            </Menu.Item>
            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/admin-routes/reports/admission">
                <UserAddOutlined />
                Admissions Report
              </Link>
            </Menu.Item>

            <Menu.Item key="custom-views/extras/testimonials">
              <Link to="/admin-routes/reports/fees">
                <TransactionOutlined />
                Fees Report
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      )}
    </>
  );
};

export default AdminNav;
