import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const SuperAdminRoutes = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
    {/* Dashboard */}
    <Route
      path={`${match.url}/dashboard`}
      component={asyncComponent(() => import("./dashboard"))}
    />

    {/* Assignment */}
    {/* <Route path={`${match.url}/assignment/create`} component={asyncComponent(() => import('./assignment/create'))} /> */}
    <Route
      path={`${match.url}/assignment/table`}
      component={asyncComponent(() => import("./assignment/table"))}
    />
    <Route
      path={`${match.url}/pdf/bonafide`}
      component={asyncComponent(() => import("../../pdfs/bonafide"))}
    />
    <Route
      path={`${match.url}/pdf/lc`}
      component={asyncComponent(() => import("../../pdfs/leavingCertificate"))}
    />

    {/* Placement */}
    <Route
      path={`${match.url}/placement/table`}
      component={asyncComponent(() => import("./placement/table"))}
    />
    <Route
      path={`${match.url}/placement/create`}
      component={asyncComponent(() => import("./placement/create"))}
    />

    {/* My Profile */}
    <Route
      path={`${match.url}/myProfile/update`}
      component={asyncComponent(() => import("../common/myProfile/edit"))}
    />
    <Route
      path={`${match.url}/myProfile/view`}
      component={asyncComponent(() => import("../common/myProfile/view"))}
    />

    {/* Staff */}
    <Route
      path={`${match.url}/faculty/table`}
      component={asyncComponent(() => import("./faculty/table"))}
    />
    <Route
      path={`${match.url}/faculty/create`}
      component={asyncComponent(() => import("./faculty/create"))}
    />
    <Route
      path={`${match.url}/faculty/edit`}
      component={asyncComponent(() => import("./faculty/create"))}
    />
    <Route
      path={`${match.url}/faculty/assign`}
      component={asyncComponent(() => import("./faculty/assign"))}
    />
    <Route
      path={`${match.url}/faculty/details`}
      component={asyncComponent(() => import("./faculty/details"))}
    />

    <Route
      path={`${match.url}/faculty/assessment/edit`}
      component={asyncComponent(() =>
        import("../common/assessment/editAssessment")
      )}
    />
    <Route
      path={`${match.url}/faculty/assessment/form`}
      component={asyncComponent(() => import("../common/assessment/form"))}
    />
    <Route
      path={`${match.url}/faculty/assessment/table`}
      component={asyncComponent(() => import("../common/assessment/table"))}
    />

    <Route
      exact
      path={`${match.url}/faculty/kyc`}
      component={asyncComponent(() => import("./faculty/kyc"))}
    />

    {/* Admission */}
    {/* <Route path={`${match.url}/admission/dashboard`} component={asyncComponent(()=> import('./admission/dashboard'))}/> */}

    <Route
      path={`${match.url}/admission/table`}
      component={asyncComponent(() => import("./admission/table"))}
    />
    <Route
      path={`${match.url}/admission/create`}
      component={asyncComponent(() => import("./admission/Create"))}
    />
    <Route
      path={`${match.url}/admission/edit`}
      component={asyncComponent(() => import("./admission/Create"))}
    />

    {/* News */}
    <Route
      path={`${match.url}/news/table`}
      component={asyncComponent(() => import("../common/news/table"))}
    />
    <Route
      path={`${match.url}/news/create`}
      component={asyncComponent(() => import("../common/news/create"))}
    />
    <Route
      path={`${match.url}/news/edit`}
      component={asyncComponent(() => import("../common/news/create"))}
    />

    {/* Cretificate */}
    <Route
      path={`${match.url}/certificate/request/`}
      component={asyncComponent(() => import("./certificate/request"))}
    />
    <Route
      path={`${match.url}/certificate/table`}
      component={asyncComponent(() => import("./certificate/table"))}
    />

    {/* Registraion */}
    <Route
      path={`${match.url}/registration/table`}
      component={asyncComponent(() => import("./registration/table"))}
    />
    {/* <Route path={`${match.url}/registration/dashboard`} component={asyncComponent(()=> import('./registration/dashboard'))}/> */}
    <Route
      path={`${match.url}/registration/verify`}
      component={asyncComponent(() => import("./registration/verification"))}
    />

    {/* Payment */}
    <Route
      path={`${match.url}/payment/table`}
      component={asyncComponent(() => import("./payment/table"))}
    />
    <Route
      path={`${match.url}/payment/receipt`}
      component={asyncComponent(() => import("./payment/receipt"))}
    />
    <Route
      path={`${match.url}/payment/module`}
      component={asyncComponent(() => import("./payment/module"))}
    />
    <Route
      path={`${match.url}/payment/bankForm`}
      component={asyncComponent(() => import("./payment/form"))}
    />

    {/* Attendance */}
    <Route
      path={`${match.url}/attendance/table`}
      component={asyncComponent(() => import("./attendance/table"))}
    />

    {/* Event */}
    <Route
      path={`${match.url}/event/table`}
      component={asyncComponent(() => import("./event/table"))}
    />
    <Route
      path={`${match.url}/event/create`}
      component={asyncComponent(() => import("./event/create"))}
    />
    <Route
      path={`${match.url}/event/edit`}
      component={asyncComponent(() => import("./event/create"))}
    />

    {/* Syllabus */}
    <Route
      path={`${match.url}/syllabus/table`}
      component={asyncComponent(() => import("./syllabus/table"))}
    />

    <Route
      path={`${match.url}/syllabus/create`}
      component={asyncComponent(() => import("./syllabus/create"))}
    />

    <Route
      path={`${match.url}/syllabus/edit`}
      component={asyncComponent(() => import("./syllabus/create"))}
    />

    {/* Course */}
    <Route
      path={`${match.url}/course/table`}
      component={asyncComponent(() => import("./course/table"))}
    />
    <Route
      path={`${match.url}/course/create`}
      component={asyncComponent(() => import("./course/form"))}
    />
    <Route
      path={`${match.url}/course/edit`}
      component={asyncComponent(() => import("./course/form"))}
    />

    {/* Batch */}
    <Route
      path={`${match.url}/batch/table`}
      component={asyncComponent(() => import("./batch/table"))}
    />
    <Route
      path={`${match.url}/batch/create`}
      component={asyncComponent(() => import("./batch/create"))}
    />
    <Route
      path={`${match.url}/batch/edit`}
      component={asyncComponent(() => import("./batch/create"))}
    />

    {/* Library */}
    <Route
      path={`${match.url}/book/table`}
      component={asyncComponent(() => import("./book/table"))}
    />
    <Route
      path={`${match.url}/book/inouttable`}
      component={asyncComponent(() => import("./book/inOutTable"))}
    />
    <Route
      path={`${match.url}/book/create`}
      component={asyncComponent(() => import("./book/create"))}
    />
    <Route
      path={`${match.url}/book/edit`}
      component={asyncComponent(() => import("./book/create"))}
    />

    {/* Academics */}
    <Route
      path={`${match.url}/academicYear/table`}
      component={asyncComponent(() => import("./academic/table"))}
    />
    <Route
      path={`${match.url}/academicYear/create`}
      component={asyncComponent(() => import("./academic/create"))}
    />
    <Route
      path={`${match.url}/academicYear/edit`}
      component={asyncComponent(() => import("./academic/create"))}
    />

    {/* Division */}
    <Route
      path={`${match.url}/division/table`}
      component={asyncComponent(() => import("./division/table"))}
    />
    <Route
      path={`${match.url}/division/create`}
      component={asyncComponent(() => import("./division/create"))}
    />
    <Route
      path={`${match.url}/division/edit`}
      component={asyncComponent(() => import("./division/create"))}
    />

    {/* Student */}
    <Route
      path={`${match.url}/student/table`}
      component={asyncComponent(() => import("./student/table"))}
    />
    <Route
      path={`${match.url}/student/details`}
      component={asyncComponent(() => import("./student/details"))}
    />

    {/* Department */}
    <Route
      path={`${match.url}/department/table`}
      component={asyncComponent(() => import("./department/table"))}
    />
    <Route
      path={`${match.url}/department/create`}
      component={asyncComponent(() => import("./department/create"))}
    />
    <Route
      path={`${match.url}/department/edit`}
      component={asyncComponent(() => import("./department/create"))}
    />

    {/* Designation */}
    <Route
      path={`${match.url}/designation/table`}
      component={asyncComponent(() => import("./designation/table"))}
    />
    <Route
      path={`${match.url}/designation/create`}
      component={asyncComponent(() => import("./designation/create"))}
    />
    <Route
      path={`${match.url}/designation/edit`}
      component={asyncComponent(() => import("./designation/create"))}
    />

    {/* result */}
    <Route
      path={`${match.url}/result/table`}
      component={asyncComponent(() => import("./result/table"))}
    />

    {/* Reports */}
    <Route
      path={`${match.url}/reports/student`}
      component={asyncComponent(() => import("./reports/student"))}
    />
    <Route
      path={`${match.url}/reports/staff`}
      component={asyncComponent(() => import("./reports/staff"))}
    />
    <Route
      path={`${match.url}/reports/admission`}
      component={asyncComponent(() => import("./reports/admission"))}
    />
    <Route
      path={`${match.url}/reports/fees`}
      component={asyncComponent(() => import("./reports/fees"))}
    />
  </Switch>
);

export default SuperAdminRoutes;
