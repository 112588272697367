import React from "react";

export const taskList = [
  {
    id: 1,
    title: 'Finalize semester syllabus for Computer Engineering',
    completed: false,
    user: {
      department: 'Computer Engineering',
      avatar: "https://via.placeholder.com/150"
    },
    dueDate: 'Tomorrow',
    tags: [1, 2]
  },
  {
    id: 2,
    title: 'Schedule lab sessions for Physics class',
    completed: false,
    user: {
      department: 'Physics',
      avatar: "https://via.placeholder.com/150"
    },
    dueDate: 'Today',
    tags: [3, 4]
  },
  {
    id: 3,
    title: 'Update student attendance for Database Management System',
    completed: false,
    user: {
      department: 'Computer Engineering',
      avatar: "https://via.placeholder.com/150"
    },
    dueDate: '21 Jul',
    tags: [1, 5]
  },
  {
    id: 4,
    title: 'Organize cultural fest committee meeting',
    completed: false,
    user: {
      department: 'Cultural Committee',
      avatar: "https://via.placeholder.com/150"
    },
    dueDate: '25 Jul',
    tags: [6]
  }
];

export const recentActivity = [
  {
    id: 1,
    day: 'Today',
    tasks: [
      {
        id: 1,
        name: 'Prof. Sharma',
        // title: [<span className="gx-link" key={1}>Prof. Sharma</span>, ' finalized marks for ',
        //   <span className="gx-link" key={2}>Object-Oriented Programming</span>],
        title: [<b>Arts and Culture Festival:</b>, ' A Celebration of Creativity'],
        avatar: "https://images.hindustantimes.com/rf/image_size_960x540/HT/p2/2016/12/28/Pictures/jewel-in-the-crown_d6a83268-ccef-11e6-b3cb-dcd306bf19b8.JPG",
        imageList: [],
      },
      {
        id: 2,
        name: 'Dr. Ramesh',
        // title: ['Updated course material in ',
        //   <span className="gx-link" key={3}>Quantum Mechanics</span>],
        title: [<b>Environmental Awareness Campaign:</b>, ' Protecting Our Planet'],
        avatar: "https://aimsolder.com/sites/default/files/environmental_sustainability.jpg",
        imageList: [],
      }
    ]
  },
  {
    id: 2,
    day: 'Tomorrow',
    tasks: [
      {
        id: 3,
        name: 'Dr. Mehta',
        // title: ['Uploaded study material in ',
        //   <span className="gx-link" key={4}>Digital Electronics</span>],
        title: [<b>Food and Beverage Expo:</b>, ' Tastes of the World'],
        avatar: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Good_Food_Display_-_NCI_Visuals_Online.jpg",
        imageList: ["https://img.freepik.com/free-photo/top-view-arrangement-with-food-wooden-background_23-2148308806.jpg?w=2000", "https://www.eatingwell.com/thmb/088YHsNmHkUQ7iNGP4375MiAXOY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/article_7866255_foods-you-should-eat-every-week-to-lose-weight_-04-d58e9c481bce4a29b47295baade4072d.jpg"],
      }
    ]
  }
];

export const ticketList = [
  {
    id: 1,
    avatar: "https://www.innocenthearts.in/wp-content/uploads/2021/06/Scholarship.jpg",
    title: 'Scholarship Application Deadline Extension',
    description: [<span>The deadline for scholarship applications has been extended to 28th August.</span>],
    status: 2
  },
  {
    id: 2,
    avatar: "https://en.tums.ac.ir/images/medicine/en/pageBuilder/editor/2022/1642320121-1632640474-exam-schedule.png",
    title: 'Exam Schedule Release',
    description: [<span>The final exam schedule for the current semester is now available. Please check your student portal for details.</span>],
    status: 1
  }
];

export const taskStatus = [
  {
    id: 1,
    title: 'Urgent',
    color: 'red'
  },
  {
    id: 2,
    title: 'High',
    color: 'orange'
  },
  {
    id: 3,
    title: 'Medium',
    color: 'yellow'
  },
  {
    id: 4,
    title: 'Low',
    color: 'green'
  }
];

export const taskTags = [
  {
    id: 1,
    name: 'Computer Engineering',
    color: 'blue',
  },
  {
    id: 2,
    name: 'Physics',
    color: 'green',
  },
  {
    id: 3,
    name: 'Lab Sessions',
    color: 'purple',
  },
  {
    id: 4,
    name: 'Syllabus Finalization',
    color: 'yellow',
  },
  {
    id: 5,
    name: 'Attendance',
    color: 'red',
  },
  {
    id: 6,
    name: 'Events',
    color: 'orange',
  }
];


export const siteVisit = [
  {name: '1', thisYear: 0, lastYear: 0},
  {name: '2', thisYear: 0, lastYear: 1},
  {name: '3', thisYear: 5, lastYear: 2},
  {name: '4', thisYear: 10, lastYear: 0},
  {name: '5', thisYear: 4, lastYear: 1},
  {name: '6', thisYear: 16, lastYear: 3},
  {name: '7', thisYear: 5, lastYear: 1},
  {name: '8', thisYear: 11, lastYear: 5},
  {name: '9', thisYear: 6, lastYear: 2},
  {name: '10', thisYear: 11, lastYear: 3},
  {name: '11', thisYear: 30, lastYear: 2},
  {name: '12', thisYear: 10, lastYear: 1},
  {name: '13', thisYear: 13, lastYear: 0},
  {name: '14', thisYear: 4, lastYear: 2},
  {name: '15', thisYear: 3, lastYear: 8},
  {name: '16', thisYear: 1, lastYear: 0},
  {name: '17', thisYear: 0, lastYear: 0},
];


export const totalSaleData = [
  {name: 'JAN', price: 600},
  {name: 'FEB', price: 3398},
  {name: 'MAR', price: 1200},
  {name: 'APR', price: 4908},
  {name: 'MAY', price: 2908},
];

export const totalRevenueData = [
  {name: 'JAN', thisYear: 60},
  {name: 'FEB', thisYear: 90},
  {name: 'MAR', thisYear: 50},
  {name: 'APR', thisYear: 75},
  {name: 'MAY', thisYear: 60},
  {name: 'JUN', thisYear: 85},
  {name: 'JUL', thisYear: 20},
  {name: 'AUG', thisYear: 75},
  {name: 'SEP', thisYear: 60},
  {name: 'OCT', thisYear: 40},
  {name: 'NOV', thisYear: 75},
  {name: 'DEC', thisYear: 25},
];

export const trafficData = [
  {name: 'Page A', value: 0},
  {name: 'Page B', value: 2000},
  {name: 'Page C', value: 600},
  {name: 'Page D', value: 4400},
  {name: 'Page D', value: 900},
  {name: 'Page H', value: 4860},
];

export const feesPendingData = [
  {name: 'Page A', value: 90},
  {name: 'Page B', value: 70},
  {name: 'Page C', value: 60},
  {name: 'Page D', value: 44},
  {name: 'Page D', value: 35},
  {name: 'Page H', value: 29},
];
