import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FacultyRoutes = ({ match }) => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/senior-faculty-routes/profile/form"
    )
      history.replace("/senior-faculty-routes/profile/form");

    if (authUser.isProfileCompleted && authUser.kycStatus !== "Approved") {
      history.replace("/senior-faculty-routes/myProfile/kyc");
    }
  }, []);
  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />

      <Route
        exact
        path={`${match.url}/profile/form`}
        component={asyncComponent(() => import("../common/facultyKYC"))}
      />

      {/* My Profile */}
      <Route
        path={`${match.url}/myProfile/kyc`}
        component={asyncComponent(() => import("../common/kycStatusPage"))}
      />
      <Route
        path={`${match.url}/myProfile/update`}
        component={asyncComponent(() => import("../common/myProfile/edit"))}
      />
      <Route
        path={`${match.url}/myProfile/view`}
        component={asyncComponent(() => import("../common/myProfile/view"))}
      />

      {/* Assessment */}
      <Route
        path={`${match.url}/faculty/assessment/edit`}
        component={asyncComponent(() =>
          import("../common/assessment/editAssessment")
        )}
      />
      <Route
        path={`${match.url}/assessment/form`}
        component={asyncComponent(() => import("../common/assessment/form"))}
      />
      <Route
        path={`${match.url}/assessment/table`}
        component={asyncComponent(() => import("../common/assessment/table"))}
      />
      {/* 
      <Route
        path={`${match.url}/assessment/nextAssessmentForm`}
        component={asyncComponent(() =>
          import("./assessment/nextAssessmentForm")
        )}
      /> */}

      {/* Attendance */}
      <Route
        path={`${match.url}/attendance/table`}
        component={asyncComponent(() => import("./attendance/table"))}
      />
      <Route
        path={`${match.url}/attendance/create`}
        component={asyncComponent(() => import("./attendance/form"))}
      />
      <Route
        path={`${match.url}/attendance/edit`}
        component={asyncComponent(() => import("./attendance/form"))}
      />
      <Route
        path={`${match.url}/attendance/view`}
        component={asyncComponent(() => import("./attendance/view"))}
      />

      {/* Staff Profiler */}
      <Route
        path={`${match.url}/profiler/staff/table`}
        component={asyncComponent(() => import("./profiler/staff/table"))}
      />

      {/* Student Profiler */}
      <Route
        path={`${match.url}/profiler/student/table`}
        component={asyncComponent(() => import("./profiler/student/table"))}
      />

      {/* Assignment */}
      <Route
        path={`${match.url}/assignment/table`}
        component={asyncComponent(() => import("./assignment/table"))}
      />
      <Route
        path={`${match.url}/assignment/create`}
        component={asyncComponent(() => import("./assignment/form"))}
      />
      <Route
        path={`${match.url}/assignment/edit`}
        component={asyncComponent(() => import("./assignment/form"))}
      />
      <Route
        path={`${match.url}/assignment/view`}
        component={asyncComponent(() => import("./assignment/view"))}
      />

      {/* Result */}
      <Route
        path={`${match.url}/result/table`}
        component={asyncComponent(() => import("./result/table"))}
      />
      <Route
        path={`${match.url}/result/create`}
        component={asyncComponent(() => import("./result/create"))}
      />
      <Route
        path={`${match.url}/result/edit`}
        component={asyncComponent(() => import("./result/create"))}
      />
      <Route
        path={`${match.url}/result/view`}
        component={asyncComponent(() => import("./result/view"))}
      />

      {/* Event */}
      <Route
        path={`${match.url}/event/table`}
        component={asyncComponent(() => import("./event/table"))}
      />
      <Route
        path={`${match.url}/event/create`}
        component={asyncComponent(() => import("./event/create"))}
      />
      <Route
        path={`${match.url}/event/edit`}
        component={asyncComponent(() => import("./event/create"))}
      />

      {/* News */}
      <Route
        path={`${match.url}/news/table`}
        component={asyncComponent(() => import("../common/news/table"))}
      />
      {/* <Route
        path={`${match.url}/news/create`}
        component={asyncComponent(() => import("./news/create"))}
      />
      <Route
        path={`${match.url}/news/edit`}
        component={asyncComponent(() => import("./news/create"))}
      /> */}

      <Route
        path={`${match.url}/student/details`}
        component={asyncComponent(() => import("./student/details"))}
      />

      <Route
        path={`${match.url}/signUp`}
        component={asyncComponent(() => import("./signUp/profileInformation"))}
      />

      <Route
        path={`${match.url}/profile/details/form`}
        component={asyncComponent(() => import("./SignIn"))}
      />
    </Switch>
  );
};

export default FacultyRoutes;
