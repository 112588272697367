import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import Dashboard from "../../routes/dashboard";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import {
  BarChartOutlined,
  BookOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CiCircleTwoTone,
  ClusterOutlined,
  ContainerOutlined,
  EditOutlined,
  FileProtectOutlined,
  GroupOutlined,
  MenuUnfoldOutlined,
  SolutionOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/auth";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const JuniorFacultyNav = (props) => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);
  const history = useHistory();
  const { authUser } = useContext(AuthContext);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      {authUser.kycStatus === "Approved" && (
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={!props.isSidebar ? "lite" : "dark"}
          mode={props.isSidebar ? "inline" : "horizontal"}
        >
          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="main"
            title={"Dashboard"}
            onTitleClick={() =>
              history.push("/junior-faculty-routes/dashboard")
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="Attendance"
            title={"Attendance "}
            onTitleClick={() =>
              history.push(`/junior-faculty-routes/attendance/table`)
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="Assignment"
            title={"Assignment"}
            onTitleClick={() =>
              history.push(`/junior-faculty-routes/assignment/table`)
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="Result"
            title={"Result"}
            onTitleClick={() =>
              history.push(`/junior-faculty-routes/result/table`)
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="student"
            title={"Students"}
            onTitleClick={() =>
              history.push(`/junior-faculty-routes/student/table`)
            }
          ></SubMenu>

          <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key="news"
            title={"News & Events"}
            onTitleClick={() =>
              history.push(`/junior-faculty-routes/news/table`)
            }
          ></SubMenu>

          {/* <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="event"
        title={"Event"}
        onTitleClick={() => history.push(`/junior-faculty-routes/event/table`)}
      ></SubMenu> */}
        </Menu>
      )}
    </>
  );
};

export default JuniorFacultyNav;
