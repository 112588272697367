import React from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row } from "antd";

import Metrics from "components/Metrics";
import { connect } from "react-redux";

const data = [
  { name: "JAN", lastYear: 132000, thisYear: 396000 },
  { name: "FEB", lastYear: 275000, thisYear: 495000 },
  { name: "MAR", lastYear: 385000, thisYear: 660000 },
  { name: "JUN", lastYear: 275000, thisYear: 495000 },
  { name: "AUG", lastYear: 110000, thisYear: 440000 },
  { name: "SEP", lastYear: 220000, thisYear: 220000 },
  { name: "OCT", lastYear: 220000, thisYear: 275000 },
  { name: "NOV", lastYear: 220000, thisYear: 660000 },
  { name: "DEC", lastYear: 110000, thisYear: 440000 },
];

const TotalRevenueCard = ({ width, data }) => {
  return (
    <>
      <h6 className="gx-text-uppercase gx-mb-2 gx-mt-4 gx-mb-xl-4">
        TOTAL REVENUE
      </h6>
      <Row>
        <Col xl={11} lg={12} md={24} sm={12} xs={12}>
          <h5 className="gx-mb-1 gx-revenue-title">₹{data}</h5>
          <p className="gx-mb-md-0 gx-text-light">Fees Collection</p>
        </Col>
        <Col xl={13} lg={12} md={24} sm={12} xs={12}>
          <ResponsiveContainer className="gx-barchart" width="100%" height={70}>
            <BarChart
              data={data}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip />
              <Bar
                dataKey="lastYear"
                stackId="a"
                fill="#1494ff"
                barSize={width <= 575 ? 4 : 8}
              />
              <BarChart
                dataKey="thisYear"
                stackId="a"
                fill="#FE9E15"
                barSize={width <= 575 ? 4 : 8}
              />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ settings }) => {
  const { width } = settings;
  return { width };
};
export default connect(mapStateToProps)(TotalRevenueCard);
