import React, { useState } from "react";
import { Button, Dropdown, Layout, Menu, message, Popover, Tag } from "antd";
import Icon from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../../appRedux/actions/Setting";
import IntlMessages from "../../../util/IntlMessages";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import SuperAdminNav from "../superAdminNav";

const { Header } = Layout;

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info("Click on menu item.");
}

const InsideHeader = (props) => {
  const [searchText, setSearchText] = useState("");
  const { locale } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <div className="gx-header-horizontal-top">
        <div className="gx-container">
          <div className="gx-header-horizontal-top-flex">
            <div className="gx-header-horizontal-top-left">
              <i className="icon icon-home gx-mr-3" />
              {/* <p className="gx-mb-0 gx-text-truncate"><IntlMessages id="app.announced"/></p> */}
              <p className="gx-mb-0 gx-text-truncate">
                Institute of Engineering and Technology
              </p>
            </div>
            {/* <ul className="gx-login-list">
              <li>Login</li>
              <li>Signup</li>
            </ul> */}
          </div>
        </div>
      </div>

      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>

            {width >= TAB_SIZE && (
              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve">
                {props.navbar ? props.navbar : <></>}
              </div>
            )}

            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-msg">
                <Tag>
                  {JSON.parse(localStorage.getItem("user"))?.primaryRole
                    ?.designationId?.name ?? "Student"}
                </Tag>
              </li>
              <li>
                <UserInfo myProfileURL={props.myProfileURL} />
              </li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default InsideHeader;
