import React from "react";
import {Cell, Pie, PieChart, Bar, BarChart, ResponsiveContainer, Tooltip} from "recharts";

import Widget from "components/Widget";

const data = [{name: 'Male Students', value: 942}, {name: 'Female Students', value: 498},
  {name: 'Male Staff', value: 67}, {name: 'Female Staff', value: 33}];

const COLORS = ['#5797fc', '#FA8C16', '#f5222d', '#d9d9d9'];

const data1 = [
  {name: 'JAN', female: 132, male: 396},
  {name: 'FEB', female: 275, male: 495},
  {name: 'MAR', female: 385, male: 660},
  {name: 'JUN', female: 275, male: 495},
  {name: 'AUG', female: 110, male: 440},
  {name: 'SEP', female: 220, male: 220},
  {name: 'OCT', female: 220, male: 275},
  {name: 'NOV', female: 220, male: 660},
  {name: 'DEC', female: 110, male: 440}
]


const TaskByStatus = ({width}) => {
  return (
    <Widget title={
      <h2 className="h4 gx-text-capitalize gx-mb-0"> College Gender Composition</h2>
    } styleName="gx-text-center">
      <div className="gx-py-3">
        <ResponsiveContainer width="100%" height={150}>
          <BarChart data={data1} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
            <Tooltip/>
            <Bar dataKey="male" name="Male" stackId="a" fill="#1494ff" barSize={width <= 575 ? 4 : 8}/>
            <Bar dataKey="female" name="Female" stackId="a" fill="#FE9E15" barSize={width <= 575 ? 4 : 8}/>
          </BarChart>
          {/* <PieChart>
            <Tooltip/>
            <text
              x="50%" className="h1"
              y="50%" textAnchor="middle" dominantBaseline="middle">
              {'Gender'}
            </text>
            <Pie
              data={data} dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={47}
              outerRadius={57}
              fill="#8884d8"
            >
              {
                data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
              }
            </Pie>
          </PieChart> */}
        </ResponsiveContainer>
      </div>
    </Widget>
  );
};
export default TaskByStatus;
