import React, { useContext, useEffect } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InsideHeader from "../Topbar/InsideHeader/index";

import { footerText } from "../../util/config";
import App from "../../routes/index";
import Customizer from "../Customizer";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";

import SuperAdminNav from "../Topbar/superAdminNav";
import StudentNav from "../Topbar/studentNav";
import AdminNav from "../Topbar/adminNav";
import HodNav from "../Topbar/hodNav";
import JuniorFacultyNav from "../Topbar/juniorFacultyNav";
import SeniorFacultyNav from "../Topbar/seniorFacultyNav";
import PrincipleDirectorNav from "../Topbar/principle-directorNav";
import LibraryNav from "../Topbar/libraryNav";
import { AuthContext } from "../../context/auth";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const StudentApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  return (
    <Layout className="gx-app-layout">
      <AppSidebar
        navStyle={navStyle}
        navbar={
          (authUser?.primaryRole?.name === "Admin" && <AdminNav isSidebar />) ||
          (authUser?.primaryRole?.name === "Super Admin" && (
            <SuperAdminNav isSidebar />
          )) ||
          (authUser?.primaryRole?.name === "HOD" && <HodNav isSidebar />) ||
          (authUser?.primaryRole?.name === "Junior Faculty" && (
            <JuniorFacultyNav isSidebar isKycDone={true} />
          )) ||
          (authUser?.primaryRole?.name === "Senior Faculty" && (
            <SeniorFacultyNav isSidebar />
          )) ||
          (authUser?.primaryRole?.name === "Principal" && (
            <PrincipleDirectorNav isSidebar />
          )) ||
          (authUser?.primaryRole?.name === "Librarian" && (
            <LibraryNav isSidebar />
          )) ||
          ((!authUser?.primaryRole ||
            authUser?.primaryRole?.name === "Student") && (
            <StudentNav isSidebar />
          ))
        }
      />
      <Layout>
        <InsideHeader
          navbar={
            (authUser?.primaryRole?.name === "Admin" && <AdminNav />) ||
            (authUser?.primaryRole?.name === "Super Admin" && (
              <SuperAdminNav />
            )) ||
            (authUser?.primaryRole?.name === "HOD" && <HodNav />) ||
            (authUser?.primaryRole?.name === "Junior Faculty" && (
              <JuniorFacultyNav />
            )) ||
            (authUser?.primaryRole?.name === "Senior Faculty" && (
              <SeniorFacultyNav />
            )) ||
            (authUser?.primaryRole?.name === "Principal" && (
              <PrincipleDirectorNav />
            )) ||
            (authUser?.primaryRole?.name === "Librarian" && <LibraryNav />) ||
            ((!authUser?.primaryRole ||
              authUser?.primaryRole?.name === "Student") && (
              <StudentNav
                isAdmissionApproved={
                  authUser?.primaryRole?.divisionId !== undefined || null
                }
              />
            ))
          }
          myProfileURL={
            (authUser?.primaryRole?.name === "Super Admin" &&
              "/super-admin-routes") ||
            (authUser?.primaryRole?.name === "Admin" && "/admin-routes") ||
            (authUser?.primaryRole?.name === "HOD" && "/hod-routes") ||
            (authUser?.primaryRole?.name === "Principal" &&
              "/principle-director-routes") ||
            (authUser?.primaryRole?.name === "Librarian" &&
              "/library-routes") ||
            (authUser?.primaryRole?.name === "Senior Faculty" &&
              "/senior-faculty-routes") ||
            (authUser?.primaryRole?.name === "Junior Faculty" &&
              "/junior-faculty-routes") ||
            ((!authUser?.primaryRole ||
              authUser?.primaryRole?.name === "Student") &&
              "/student-routes/myProfile/personal/information")
          }
        />
        <Content
          className={`gx-layout-content ${getContainerClass(navStyle)} `}
        >
          <App match={match} />
          <Footer>
            <div className="gx-layout-footer-content">{footerText}</div>
          </Footer>
        </Content>
      </Layout>
      <Customizer />
    </Layout>
  );
};
export default StudentApp;
