import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import { httpClient } from "../util/Api";
import { storedUser } from "../util/storage";
import { useContext } from "react";
import { AuthContext } from "../context/auth";

const SignIn = () => {
  const dispatch = useDispatch();
  // const { loader, alertMessage, showMessage, authUser } = useSelector(
  //   ({ auth }) => auth
  // );
  const history = useHistory();
  const [form] = Form.useForm();

  const { authUser, setAuthUser } = useContext(AuthContext);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handleSignIn = async (values) => {
    const formdata = form.getFieldsValue();
    try {
      const res = await httpClient.post("/auth/login", formdata);
      if (res.status.toString().startsWith("2")) {
        localStorage.setItem("accessToken", res.data.accessToken);
        message.success("Signed in.");
        const res2 = await httpClient.get(`/user/${res.data.user._id}`);
        setAuthUser(res2.data);
        localStorage.setItem("user", JSON.stringify(res2.data));
        history.replace("/");
      } else {
        console.log({ requestResponse: res });
        message.error(
          res?.response?.data?.message ||
            "Login failed. Email or password wrong."
        );
      }
    } catch (error) {
      console.log({ loginError: error });
      message.error(
        error?.response?.data?.message ||
          "Login failed. Email or password wrong."
      );
    }
  };

  const handleForgotPassword = async (values) => {
    try {
      const res = await httpClient.post("/auth/forgotPassword", values);
      if (res.status.toString().startsWith("2")) {
        message.success("Password reset link sent to your email.");
      } else {
        console.log({ requestResponse: res });
        message.error(res?.response?.data?.message || "Password reset failed.");
      }
    } catch (error) {
      console.log({ loginError: error });
    }
  };

  useEffect(() => {
    if (storedUser() !== null) {
      history.push("/");
    }
  }, []);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        {!isForgotPassword ? (
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={"https://via.placeholder.com/272x395"}
                  alt="Neature"
                />
              </div>
              {/* <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <h2>Impluse Institute of Technology</h2>
              <p>Create An account or sign in</p>
            </div> */}
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signIn" />
                </h1>
                <img src="/SignIn.png" alt="SignIn" />
                <p>
                  <Link to="/signup" style={{ color: "white" }}>
                    Create an Account or Sign Up{" "}
                  </Link>
                </p>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                form={form}
                name="signin"
                layout="vertical"
                onFinish={handleSignIn}
                style={{ marginTop: "10%" }}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input
                    suffix={<UserOutlined />}
                    placeholder="Enter your email"
                  />
                </Form.Item>

                <Form.Item
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="password"
                  label="Password"
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                {/* <Form.Item>
                <Checkbox>
                  <IntlMessages id="appModule.iAccept" />
                </Checkbox>
                <span className="gx-signup-form-forgot gx-link">
                  <IntlMessages id="appModule.termAndCondition" />
                </span>
              </Form.Item> */}

                <Form.Item style={{ width: "100%", marginTop: "-20px" }}>
                  <Space direction="vertical" size="small">
                    <Button
                      type="link"
                      onClick={() => {
                        form.resetFields();
                        setIsForgotPassword(true);
                      }}
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Forgot Password?
                    </Button>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      <IntlMessages id="app.userAuth.signIn" />
                    </Button>
                  </Space>
                  {/* <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link> */}
                </Form.Item>
                {/* <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <FacebookOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <GithubOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <TwitterOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}
                    />
                  </li>
                </ul>
              </div>
              <span className="gx-text-light gx-fs-sm">
                {" "}
                demo user email: 'demo@example.com' and password: 'demo#123'
              </span> */}
              </Form>
            </div>

            {/* {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null} */}
          </div>
        ) : (
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={"https://via.placeholder.com/272x395"}
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid">
                <h1>Forgot Password</h1>
                <img src="/SignIn.png" alt="SignIn" />
                <p>
                  <Link to="/signup" style={{ color: "white" }}>
                    Create an Account or Sign Up{" "}
                  </Link>
                </p>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                name="signin"
                layout="vertical"
                form={form}
                onFinish={handleForgotPassword}
                style={{ marginTop: "10%" }}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input
                    suffix={<UserOutlined />}
                    placeholder="Enter your email"
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  <Space direction="vertical">
                    <Button
                      type="link"
                      onClick={() => {
                        form.resetFields();
                        setIsForgotPassword(false);
                      }}
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Back to Sign In
                    </Button>

                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
