import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { siteVisit } from "../../../routes/main/dashboard/CRM/data";
import Metrics from "components/Metrics";

const SiteVisit = () => (
  <Metrics title="ATTENDANCE">
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      {/* <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Attendance Graph</h6> */}
      <ResponsiveContainer width="100%" height={52}>
        <AreaChart
          data={siteVisit}
          margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
        >
          <Tooltip />
          <CartesianGrid horizontal={false} strokeDasharray="3 3" />
          <Area
            type="monotone"
            dataKey="thisYear"
            fillOpacity={1}
            stroke="#1494ff"
            fill="#1494ff"
          />
          <Area
            type="monotone"
            dataKey="lastYear"
            fillOpacity={1}
            stroke="#FE9E15"
            fill="#FE9E15"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  </Metrics>
);

export default SiteVisit;
