import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Modal,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { httpClient } from "../util/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  // showAuthLoader,
  // userFacebookSignIn,
  // userGithubSignIn,
  // userGoogleSignIn,
  // userSignUp,
  // userTwitterSignIn
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";
import { set } from "lodash";

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [form] = Form.useForm();
  const [isMobileOTPSent, setIsMobileOTPSent] = useState(false);
  const [isMobileVerified, setIsMobileverified] = useState(false);
  const [isEmailOTPSent, setIsEmailOTPSent] = useState(false);
  const [isEmailVerified, setIsEmailverified] = useState(false);
  const [sendEmailOTPLoading, setSendEmailOTPLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const allowedEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "live.com",
    "icloud.com",
    "aol.com",
    "zoho.com",
    "protonmail.com",
    "yandex.com",
    "mail.com",
  ];

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setVisible(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setVisible(false);
  };

  const handleSignUp = async (values) => {
    const formdata = form.getFieldsValue();
    try {
      const userData = {
        name: `${formdata.firstName} ${formdata.middleName} ${formdata.lastName}`,
        firstName: formdata.firstName,
        middleName: formdata.middleName,
        lastName: formdata.lastName,
        email: formdata.email,
        password: formdata.password,
        gender: formdata.gender,
        mobile: formdata.mobile,
      };
      const res = await httpClient.post("/auth/signup", userData);

      if (res.status.toString().startsWith("2")) {
        const roleData = {
          userId: res.data._id,
          type: "Student",
          name: "Student",
        };
        const roleRes = await httpClient.post("/role", roleData);
        if (roleRes.status.toString().startsWith("2")) {
          message.success("Signed up. Sign in to continue.");
          history.push("/signin");
        }
      }
    } catch (error) {
      console.log(error.response);
      message.error(error.response.data.message);
    }
  };

  const handleSendMobileOTP = async (mobile) => {
    try {
      if (!/^\d{10}$/.test(mobile)) {
        message.error("Please enter a valid 10-digit mobile number!");
        return;
      }
      const res = await httpClient.post("/communication/sendOTP", {
        mobile: mobile,
      });
      if (res.status.toString().startsWith("2")) {
        message.success("OTP sent successfully");
        setIsMobileOTPSent(true);
      } else {
        message.error("Failed to send OTP");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendEmailOTP = async (email) => {
    try {
      setSendEmailOTPLoading(true);
      if (
        !new RegExp(
          `^[a-zA-Z0-9._%+-]+@(${allowedEmailDomains.join("|")})$`
        ).test(email)
      ) {
        message.error("Please enter a valid email address!");
        setSendEmailOTPLoading(false);
        return;
      }
      const res = await httpClient.post("/communication/sendEmailOTP", {
        email: email,
      });

      if (res.data.status === "success") {
        message.success("OTP sent successfully");
        setSendEmailOTPLoading(false);
        setIsEmailOTPSent(true);
      } else {
        message.error("Failed to send OTP");
        setSendEmailOTPLoading(false);
      }
    } catch (error) {
      message.error(error.response.data.error);
      console.log(error);
      setSendEmailOTPLoading(false);
    }
  };

  const verifyOTP = async (key, otp, verificationField) => {
    try {
      const res = await httpClient.post("/communication/verifyOTP", {
        key: key,
        otp: otp,
      });
      if (res.data.status) {
        if (verificationField === "mobile") {
          setIsMobileverified(true);
        }
        if (verificationField === "email") {
          setIsEmailverified(true);
        }
        message.success("OTP verified successfully");
      } else {
        message.error("Invalid OTP! Please try again.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  // const onFinish = values => {
  //   dispatch(showAuthLoader());
  //   dispatch(userSignUp(values));
  // };

  return (
    <div className="gx-app-login-wrap">
      {/* <div className="gx-app-login-container"> */}
      <div
        style={{
          position: "relative",
          maxWidth: "880px",
          width: "94%",
          margin: "0 auto",
        }}
      >
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signUp" />
              </h1>
              <img src="/SignIn.png" alt="SignIn" />
              <h4 style={{ textAlign: "center" }}>
                <Link to="/signin" style={{ color: "white" }}>
                  Login to Account or SignIn{" "}
                </Link>
              </h4>
            </div>
          </div>

          <div style={{ padding: 16, width: "60%" }}>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={handleSignUp}
              // className="gx-signin-form gx-form-row0"
            >
              <Row gutter={16} style={{ flexDirection: "row" }}>
                <Col span={8}>
                  <FormItem
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                      {
                        pattern: /^[A-Za-z]{1,15}$/,
                        message:
                          "Please enter alphabets only (up to 15 characters)!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter first name" />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    label="Middle Name"
                    name="middleName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your middle name!",
                      },
                      {
                        pattern: /^[A-Za-z]{1,15}$/,
                        message:
                          "Please enter alphabets only (up to 15 characters)!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter middle name" />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                      {
                        pattern: /^[A-Za-z]{1,15}$/,
                        message:
                          "Please enter alphabets only (up to 15 characters)!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter last name" />
                  </FormItem>
                </Col>

                <Col span={16}>
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter mobile number linked with Aadhaar",
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{10}$/.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Please enter a valid 10-digit mobile number linked with Aadhaar!"
                            )
                          );
                        },
                      },
                    ]}
                  >
                    <Space align="start">
                      <Input placeholder="E.g. 8541269730" />
                      <Button
                        type="default"
                        onClick={() =>
                          handleSendMobileOTP(form.getFieldValue("mobile"))
                        }
                      >
                        Get OTP
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="otp"
                    label="OTP"
                    rules={[
                      {
                        required: true,
                        message: "Please enter OTP sent to your phone",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter OTP"
                      // disabled={!isMobileOTPSent}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.toString().length === 6) {
                          verifyOTP(
                            form.getFieldValue("mobile"),
                            e.target.value,
                            "mobile"
                          );
                        }
                      }}
                      maxLength={6}
                      minLength={6}
                    />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <FormItem
                    label="Email"
                    name="email"
                    onChange={() => setIsEmailverified(false)}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not a valid email!",
                      },
                      {
                        pattern: new RegExp(
                          `^[a-zA-Z0-9._%+-]+@(${allowedEmailDomains.join(
                            "|"
                          )})$`
                        ),
                        message: "Please enter a valid email address!",
                      },
                    ]}
                  >
                    <Space align="start">
                      <Input placeholder="Enter your email" />
                      <Button
                        type="default"
                        onClick={() =>
                          handleSendEmailOTP(form.getFieldValue("email"))
                        }
                        loading={sendEmailOTPLoading}
                      >
                        Get OTP
                      </Button>
                    </Space>
                  </FormItem>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="otpEmail"
                    label="OTP"
                    rules={[
                      {
                        required: true,
                        message: "Please enter OTP sent to your email",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter OTP"
                      // disabled={!isMobileOTPSent}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.toString().length === 6) {
                          verifyOTP(
                            form.getFieldValue("email"),
                            e.target.value,
                            "email"
                          );
                        }
                      }}
                      maxLength={6}
                      minLength={6}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <FormItem
                    label="Gender"
                    name="gender"
                    rules={[
                      { required: true, message: "Please select gender!" },
                    ]}
                  >
                    <Select
                      placeholder="Select Gender"
                      style={{ width: "100%" }}
                    >
                      <Select.Option value="Male">Male</Select.Option>
                      <Select.Option value="Female">Female</Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    label="Password"
                    name="password"
                    rules={[
                      { required: true, message: "Please enter password!" },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                        message:
                          "Password must contain at least one uppercase, one lowercase, one digit, and one special character, and be at least 8 characters long.",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter password" />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    label="Confirm password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                        message:
                          "Password must contain at least one uppercase, one lowercase, one digit, and one special character, and be at least 8 characters long.",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("password do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm password" />
                  </FormItem>
                </Col>

                <Col span={16}>
                  <Form.Item
                    name="aadhaar"
                    label="Aadhaar Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Aadhaar number",
                      },
                      {
                        pattern: /^\d{12}$/,
                        message:
                          "Please enter a valid 12-digit Aadhaar number!",
                      },
                    ]}
                  >
                    <Space align="start">
                      <InputNumber
                        style={{ width: "100%" }}
                        controls={false}
                        placeholder="Enter up to 12 digits Aadhaar number"
                      />
                    </Space>
                  </Form.Item>
                </Col>
              </Row>

              <FormItem name="acceptTermsAndConditions" valuePropName="checked">
                <Checkbox>
                  I accept{" "}
                  <a className="gx-login-form-forgot" onClick={showModal}>
                    Terms & Conditions
                  </a>
                  <Modal
                    title="Terms & Conditions"
                    open={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <p>
                      By accessing and using the Platform, you acknowledge and
                      agree to comply with these terms and conditions. These
                      terms constitute a legally binding agreement between you
                      and the system provider. If you do not agree with any of
                      these terms, you are not permitted to access or use the
                      system.
                    </p>
                    <p>
                      <b> User Eligibility </b>
                      <br />
                      Access to the Platform is limited to authorized users,
                      including students, faculty, staff, and administrators of
                      affiliated educational institutions. Unauthorized access
                      or use of the system is strictly prohibited and may result
                      in legal action.
                    </p>
                    <p>
                      <b>Account Security </b>
                      <br />
                      You are responsible for maintaining the confidentiality of
                      your account credentials, including username and password.
                      You must not share your login information with anyone
                      else. You are also responsible for all activities that
                      occur under your account. If you suspect any unauthorized
                      access to your account, you must notify the system
                      administrator immediately.
                    </p>
                    <p>
                      <b>Data Privacy</b>
                      <br />
                      The Platform collects and processes personal and academic
                      information in compliance with applicable privacy laws and
                      regulations. By using the system, you consent to the
                      collection, storage, and use of your data for educational
                      and administrative purposes only. Your data will not be
                      shared with third parties without your consent, except as
                      required by law.
                    </p>
                    <p>
                      <b>Messaging/Communication Policy </b>
                      <br />
                      <ul>
                        <li>
                          The messaging feature within the Platform is provided
                          for communication related to academic and
                          administrative matters only. You are prohibited from
                          using the messaging feature for personal or
                          non-educational purposes.
                        </li>
                        <br />
                        <li>
                          From time to time, you may receive one-time passwords
                          (OTPs) via the messaging feature for account
                          verification purposes. These OTPs are confidential and
                          should not be shared with anyone.
                        </li>
                        <br />
                        <li>
                          You are responsible for ensuring the security of your
                          OTPs and must not disclose them to unauthorized
                          individuals. The system administrator will never
                          request OTPs or other sensitive information via
                          messaging.
                        </li>
                      </ul>
                    </p>
                  </Modal>
                </Checkbox>
              </FormItem>

              <FormItem
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.acceptTermsAndConditions !==
                  currentValues.acceptTermsAndConditions
                }
              >
                {({ getFieldValue }) => (
                  <>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                      disabled={
                        !getFieldValue("acceptTermsAndConditions") ||
                        !isEmailVerified
                      }
                    >
                      <IntlMessages id="app.userAuth.signUp" />
                    </Button>
                    {/* <span>
                      <IntlMessages id="app.userAuth.or" />
                    </span>{" "}
                    <Link to="/">
                      <IntlMessages id="app.userAuth.signIn" />
                    </Link> */}
                  </>
                )}
              </FormItem>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
