import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const PrincipleDirectorRoutes = ({ match }) => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/principle-director-routes/profile/form"
    ) {
      history.replace("/principle-director-routes/profile/form");
    }

    if (authUser.isProfileCompleted && authUser.kycStatus !== "Approved") {
      history.replace("/principle-director-routes/myProfile/kyc");
    }
  }, []);
  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        exact
        path={`${match.url}/profile/form`}
        component={asyncComponent(() => import("../common/facultyKYC"))}
      />
      <Route
        path={`${match.url}/myProfile/kyc`}
        component={asyncComponent(() => import("../common/kycStatusPage"))}
      />
      {/* Placement */}
      <Route
        path={`${match.url}/placement/table`}
        component={asyncComponent(() => import("./placement/table"))}
      />
      <Route
        path={`${match.url}/placement/create`}
        component={asyncComponent(() => import("./placement/create"))}
      />

      {/* Admission */}
      <Route
        path={`${match.url}/admission/dashboard`}
        component={asyncComponent(() => import("./admission/dashboard"))}
      />
      <Route
        path={`${match.url}/admission/create`}
        component={asyncComponent(() => import("./admission/Create"))}
      />
      <Route
        path={`${match.url}/admission/table`}
        component={asyncComponent(() => import("./admission/table"))}
      />

      {/* Student */}
      <Route
        path={`${match.url}/student/table`}
        component={asyncComponent(() => import("./student/table"))}
      />
      <Route
        path={`${match.url}/student/details`}
        component={asyncComponent(() => import("./student/details"))}
      />

      {/* Faculty */}

      <Route
        path={`${match.url}/faculty/assign`}
        component={asyncComponent(() => import("./faculty/assign"))}
      />
      <Route
        path={`${match.url}/faculty/create`}
        component={asyncComponent(() => import("./faculty/create"))}
      />
      <Route
        path={`${match.url}/faculty/table`}
        component={asyncComponent(() => import("./faculty/table"))}
      />
      <Route
        path={`${match.url}/faculty/details`}
        component={asyncComponent(() => import("./faculty/details"))}
      />

      {/* Assessment */}
      <Route
        path={`${match.url}/faculty/assessment/edit`}
        component={asyncComponent(() =>
          import("../common/assessment/editAssessment")
        )}
      />
      <Route
        path={`${match.url}/assessment/table`}
        component={asyncComponent(() => import("../common/assessment/table"))}
      />
      <Route
        path={`${match.url}/assessment/form`}
        component={asyncComponent(() => import("../common/assessment/form"))}
      />

      {/* News */}

      <Route
        path={`${match.url}/news/table`}
        component={asyncComponent(() => import("../common/news/table"))}
      />
      <Route
        path={`${match.url}/news/create`}
        component={asyncComponent(() => import("../common/news/create"))}
      />
      <Route
        path={`${match.url}/news/edit`}
        component={asyncComponent(() => import("../common/news/create"))}
      />

      {/* Departmnts */}
      {/* <Route
        path={`${match.url}/departments/table`}
        component={asyncComponent(() => import("./department/table"))}
      /> */}

      {/* Cretificate */}
      <Route
        path={`${match.url}/certificate/request/`}
        component={asyncComponent(() => import("./certificate/request"))}
      />

      {/* Attendance */}
      <Route
        path={`${match.url}/attendance/table`}
        component={asyncComponent(() => import("./attendance/table"))}
      />

      <Route
        path={`${match.url}/certificate/table`}
        component={asyncComponent(() => import("./certificate/table"))}
      />

      {/* Registraion */}
      <Route
        path={`${match.url}/registration/table`}
        component={asyncComponent(() => import("./registration/table"))}
      />
      <Route
        path={`${match.url}/registration/verify`}
        component={asyncComponent(() => import("./registration/verification"))}
      />

      {/* Payment */}
      <Route
        path={`${match.url}/payment/table`}
        component={asyncComponent(() => import("./payment/table"))}
      />
      <Route
        path={`${match.url}/payment/receipt`}
        component={asyncComponent(() => import("./payment/receipt"))}
      />
      <Route
        path={`${match.url}/payment/module`}
        component={asyncComponent(() => import("./payment/module"))}
      />

      {/* Event */}
      <Route
        path={`${match.url}/event/table`}
        component={asyncComponent(() => import("./event/table"))}
      />
      <Route
        path={`${match.url}/event/create`}
        component={asyncComponent(() => import("./event/create"))}
      />
      <Route
        path={`${match.url}/event/edit`}
        component={asyncComponent(() => import("./event/create"))}
      />

      {/* Result */}
      <Route
        path={`${match.url}/result/table`}
        component={asyncComponent(() => import("./result/table"))}
      />
      <Route
        path={`${match.url}/result/view`}
        component={asyncComponent(() => import("./result/view"))}
      />

      {/* Resports */}
      <Route
        path={`${match.url}/reports/admission`}
        component={asyncComponent(() => import("./reports/admission"))}
      />
      <Route
        path={`${match.url}/reports/fees`}
        component={asyncComponent(() => import("./reports/fees"))}
      />
      <Route
        path={`${match.url}/reports/staff`}
        component={asyncComponent(() => import("./reports/staff"))}
      />
      <Route
        path={`${match.url}/reports/student`}
        component={asyncComponent(() => import("./reports/student"))}
      />
      {/* My Profile */}
      <Route
        path={`${match.url}/myProfile/update`}
        component={asyncComponent(() => import("../common/myProfile/edit"))}
      />
      <Route
        path={`${match.url}/myProfile/view`}
        component={asyncComponent(() => import("../common/myProfile/view"))}
      />
      <Route
        path={`${match.url}/registration/view`}
        component={asyncComponent(() => import("./registration/view"))}
      />
    </Switch>
  );
};

export default PrincipleDirectorRoutes;
