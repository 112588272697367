import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { useContext } from "react";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const UserProfile = () => {
  const dispatch = useDispatch();
  const {authUser, setAuthUser} = useContext(AuthContext);
  const history = useHistory();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => history.push('/student-routes/myProfile/personal/information')}>
        My Profile
      </li>
      <li onClick={() => {
        setAuthUser(null);
        localStorage.clear();
        history.push('/signin')
      }}>Logout</li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://www.shareicon.net/data/2016/09/15/829472_man_512x512.png"}
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">Rob Farnandies<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
