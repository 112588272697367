import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminRoutes = ({ match }) => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/admin-routes/profile/form"
    ) {
      history.replace("/admin-routes/profile/form");
    }

    if (authUser.isProfileCompleted && authUser.kycStatus !== "Approved") {
      history.replace("/admin-routes/myProfile/kyc");
    }
  }, []);

  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        exact
        path={`${match.url}/profile/form`}
        component={asyncComponent(() => import("../common/facultyKYC"))}
      />
      <Route
        exact
        path={`${match.url}/registration/table`}
        component={asyncComponent(() => import("./registration/table"))}
      />
      <Route
        path={`${match.url}/registration/verify`}
        component={asyncComponent(() => import("./registration/verification"))}
      />
      <Route
        path={`${match.url}/registration/view`}
        component={asyncComponent(() => import("./registration/view"))}
      />
      <Route
        path={`${match.url}/admission/dashboard`}
        component={asyncComponent(() => import("./admission/dashboard"))}
      />
      <Route
        path={`${match.url}/admission/create`}
        component={asyncComponent(() => import("./admission/Create"))}
      />
      <Route
        path={`${match.url}/admission/edit`}
        component={asyncComponent(() => import("./admission/Create"))}
      />
      <Route
        path={`${match.url}/admission/table`}
        component={asyncComponent(() => import("./admission/table"))}
      />
      <Route
        path={`${match.url}/certificate/request`}
        component={asyncComponent(() => import("./certificate/request"))}
      />
      <Route
        path={`${match.url}/certificate/table`}
        component={asyncComponent(() => import("./certificate/table"))}
      />
      <Route
        path={`${match.url}/payment/table`}
        component={asyncComponent(() => import("./payment/table"))}
      />
      <Route
        path={`${match.url}/payment/upload`}
        component={asyncComponent(() => import("./payment/upload"))}
      />
      <Route
        path={`${match.url}/payment/receipt`}
        component={asyncComponent(() => import("./payment/receipt"))}
      />

      <Route
        path={`${match.url}/payment/module`}
        component={asyncComponent(() => import("./payment/module"))}
      />
      <Route
        path={`${match.url}/payment/form`}
        component={asyncComponent(() => import("./payment/form"))}
      />
      <Route
        path={`${match.url}/reports/student`}
        component={asyncComponent(() => import("./reports/student"))}
      />
      <Route
        path={`${match.url}/reports/staff`}
        component={asyncComponent(() => import("./reports/staff"))}
      />
      <Route
        path={`${match.url}/reports/admission`}
        component={asyncComponent(() => import("./reports/admission"))}
      />
      <Route
        path={`${match.url}/reports/fees`}
        component={asyncComponent(() => import("./reports/fees"))}
      />
      {/* Placement */}
      <Route
        path={`${match.url}/placement/table`}
        component={asyncComponent(() => import("./placement/table"))}
      />
      <Route
        path={`${match.url}/placement/form`}
        component={asyncComponent(() => import("./placement/create"))}
      />
      <Route
        path={`${match.url}/placement/upload`}
        component={asyncComponent(() => import("./placement/upload"))}
      />

      {/* My Profile */}
      <Route
        path={`${match.url}/myProfile/update`}
        component={asyncComponent(() => import("../common/myProfile/edit"))}
      />
      <Route
        path={`${match.url}/myProfile/kyc`}
        component={asyncComponent(() => import("../common/kycStatusPage"))}
      />
      <Route
        path={`${match.url}/myProfile/view`}
        component={asyncComponent(() => import("../common/myProfile/view"))}
      />

      {/* Student */}
      <Route
        path={`${match.url}/student/table`}
        component={asyncComponent(() => import("./student/table"))}
      />
      <Route
        path={`${match.url}/student/details`}
        component={asyncComponent(() => import("./student/details"))}
      />

      {/* Assessment Report */}
      <Route
        path={`${match.url}/faculty/assessment/table`}
        component={asyncComponent(() => import("./assessment/table"))}
      />
      <Route
        path={`${match.url}/faculty/assessment/form`}
        component={asyncComponent(() => import("./assessment/form"))}
      />
    </Switch>
  );
};

export default AdminRoutes;
