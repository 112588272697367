import React from "react";
import { Route, Switch } from "react-router-dom";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import SocialApps from "./socialApps/index";
import Main from "./main/index";
import Documents from "./documents/index";
import SuperAdminRoutes from "./superAdminRoutes/index";
import StudentRoutes from "./studentRoutes";
import FacultyRoutes from "./SeniorFaculty/index";
import HODRoutes from "./HOD/index";
import LibraryRoutes from "./Library/index";
import JrFacultyRoutes from "./jrFacultyRoutes";
import PrincipleDirectorRoutes from "./Principle-Director/index";
import AdminRoutes from "./adminRoutes";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}components`} component={Components} />
      <Route path={`${match.url}custom-views`} component={CustomViews} />
      <Route path={`${match.url}extensions`} component={Extensions} />
      <Route
        path={`${match.url}extra-components`}
        component={ExtraComponents}
      />
      <Route path={`${match.url}in-built-apps`} component={InBuiltApps} />
      <Route path={`${match.url}social-apps`} component={SocialApps} />
      <Route path={`${match.url}documents`} component={Documents} />
      <Route path={`${match.url}admin-routes`} component={AdminRoutes} />
      <Route path={`${match.url}student-routes`} component={StudentRoutes} />
      <Route
        path={`${match.url}super-admin-routes`}
        component={SuperAdminRoutes}
      />
      <Route
        path={`${match.url}senior-faculty-routes`}
        component={FacultyRoutes}
      />
      <Route path={`${match.url}hod-routes`} component={HODRoutes} />
      <Route path={`${match.url}library-routes`} component={LibraryRoutes} />
      <Route
        path={`${match.url}junior-faculty-routes`}
        component={JrFacultyRoutes}
      />
      <Route
        path={`${match.url}principle-director-routes`}
        component={PrincipleDirectorRoutes}
      />
    </Switch>
  </div>
);

export default App;
