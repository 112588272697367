import React from "react";

import LineIndicator from "./LineIndicator";

const SiteAudience = ({ data }) => {
  console.log({ siteAudienceData: data });
  return (
    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
      <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">
        Student Composition
      </h6>
      <ul className="gx-line-indicator">
        <li>
          <LineIndicator
            width={`${data?.studentComposition?.malePercent  || 0}%`}
            title="Male"
            color="geekblue"
            value={`${data?.studentComposition?.malePercent  || 0}%`}
          />
        </li>
        <li>
          <LineIndicator
            width={`${data?.studentComposition?.femalePercent || 0}%`}
            title="Female"
            color="orange"
            value={`${data?.studentComposition?.femalePercent || 0}%`}
          />
        </li>
      </ul>
    </div>
  );
};
export default SiteAudience;
