import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const StudentRoutes = ({ match }) => {
  const history = useHistory();

  const { authUser, setAuthUser } = useContext(AuthContext);

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/student-routes/profile/details/form"
    ) {
      history.replace("/student-routes/profile/details/form");
    }
  }, []);

  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      {/* <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./dashboard'))} /> */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./dashboard"))}
      />
      {/* SignIn/SignUp */}
      <Route
        exact
        path={`${match.url}/profile/details/form`}
        component={asyncComponent(() => import("./signIn/profileDetails"))}
      />
      <Route
        path={`${match.url}/signup`}
        component={asyncComponent(() => import("./signup"))}
      />
      <Route
        path={`${match.url}/login`}
        component={asyncComponent(() => import("./login"))}
      />
      {/* E-Kyc */}
      <Route
        path={`${match.url}/ekyc`}
        component={asyncComponent(() => import("./ekyc"))}
      />
      {/* Fees */}
      <Route
        path={`${match.url}/payments`}
        component={asyncComponent(() => import("./myProfile/payments/table"))}
      />
      {/* Edit Profile */}
      <Route
        path={`${match.url}/profile/edit/personal-information`}
        component={asyncComponent(() => import("./myProfile/edit"))}
      />
      <Route
        path={`${match.url}/profile/edit/parent-information`}
        component={asyncComponent(() => import("./myProfile/edit"))}
      />
      <Route
        path={`${match.url}/profile/edit/address`}
        component={asyncComponent(() => import("./myProfile/edit"))}
      />
      <Route
        path={`${match.url}/profile/edit/reservation-details`}
        component={asyncComponent(() => import("./myProfile/edit"))}
      />
      <Route
        path={`${match.url}/profile/edit/education-details`}
        component={asyncComponent(() => import("./myProfile/edit"))}
      />
      <Route
        path={`${match.url}/profile/edit/other-details`}
        component={asyncComponent(() => import("./myProfile/edit"))}
      />

      {/* News */}
      <Route
        path={`${match.url}/news`}
        component={asyncComponent(() => import("../common/news/table"))}
      />
      {/* <Route
        path={`${match.url}/newsview`}
        component={asyncComponent(() => import("./news/view"))}
      /> */}
      {/* Calendar */}
      <Route
        path={`${match.url}/calendar`}
        component={asyncComponent(() => import("./calendar/view"))}
      />
      {/* Attendance */}
      <Route
        path={`${match.url}/attendance`}
        component={asyncComponent(() => import("./attendance/table"))}
      />

      {/* Placement */}
      <Route
        path={`${match.url}/placement`}
        component={asyncComponent(() => import("./placement"))}
      />
      {/* Personal Information */}
      <Route
        path={`${match.url}/myProfile/personal/information`}
        component={asyncComponent(() =>
          import("./myProfile/PersonalInformation")
        )}
      />
      <Route
        path={`${match.url}/myProfile/certificate/request/form`}
        component={asyncComponent(() =>
          import("./myProfile/certificate/create")
        )}
      />
      <Route
        path={`${match.url}/myProfile/certificate/request`}
        component={asyncComponent(() =>
          import("./myProfile/certificate/request")
        )}
      />
      <Route
        path={`${match.url}/myProfile/certificate/table`}
        component={asyncComponent(() =>
          import("./myProfile/certificate/table")
        )}
      />
      <Route
        path={`${match.url}/myProfile/documents/table`}
        component={asyncComponent(() => import("./myProfile/documents/table"))}
      />
      <Route
        path={`${match.url}/myProfile/documents/upload`}
        component={asyncComponent(() => import("./myProfile/documents/upload"))}
      />
      {/* Result */}
      <Route
        path={`${match.url}/result`}
        component={asyncComponent(() => import("./result/table"))}
      />
      {/* Admission */}
      <Route
        path={`${match.url}/courses/table`}
        component={asyncComponent(() => import("./courses/table"))}
      />
      <Route
        path={`${match.url}/admission/table`}
        component={asyncComponent(() => import("./admission/table"))}
      />
      <Route
        path={`${match.url}/admission/create`}
        component={asyncComponent(() => import("./admission/create"))}
      />
      {/* Library */}
      <Route
        path={`${match.url}/library/inOutRegister`}
        component={asyncComponent(() => import("./library/inOutRegister"))}
      />
      <Route
        path={`${match.url}/library/booktable`}
        component={asyncComponent(() => import("./library/booktable"))}
      />
      <Route
        path={`${match.url}/library/bookRequest`}
        component={asyncComponent(() => import("./library/bookRequest"))}
      />
      {/* Assignment */}
      <Route
        path={`${match.url}/assignment/table`}
        component={asyncComponent(() => import("./assignment/table"))}
      />
      <Route
        path={`${match.url}/assignment/create`}
        component={asyncComponent(() => import("./assignment/create"))}
      />
      <Route
        path={`${match.url}/assignment/view`}
        component={asyncComponent(() => import("./assignment/view"))}
      />
    </Switch>
  );
};

export default StudentRoutes;
