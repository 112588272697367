import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LibraryRoutes = ({ match }) => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !authUser.isProfileCompleted &&
      history.location !== "/library-routes/profile/form"
    ) {
      history.replace("/library-routes/profile/form");
    }

    if (authUser.isProfileCompleted && authUser.kycStatus !== "Approved") {
      history.replace("/library-routes/myProfile/kyc");
    }
  }, []);

  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      {/* Dashboard */}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        exact
        path={`${match.url}/profile/form`}
        component={asyncComponent(() => import("../common/facultyKYC"))}
      />

      <Route
        path={`${match.url}/myProfile/kyc`}
        component={asyncComponent(() => import("../common/kycStatusPage"))}
      />

      {/* Book Table */}
      <Route
        path={`${match.url}/book/table`}
        component={asyncComponent(() => import("./bookTable"))}
      />
      <Route
        path={`${match.url}/book/add`}
        component={asyncComponent(() => import("./addBook"))}
      />
      <Route
        path={`${match.url}/book/details`}
        component={asyncComponent(() => import("./view"))}
      />
      <Route
        path={`${match.url}/book/upload`}
        component={asyncComponent(() => import("./upload"))}
      />
      <Route
        path={`${match.url}/book/inoutentry`}
        component={asyncComponent(() => import("./entryForm"))}
      />
      <Route
        path={`${match.url}/book/inouttable`}
        component={asyncComponent(() => import("./inOutTable"))}
      />
      <Route
        path={`${match.url}/bookRequest`}
        component={asyncComponent(() => import("./bookRequest"))}
      />
      <Route
        path={`${match.url}/news/table`}
        component={asyncComponent(() => import("../common/news/table"))}
      />
      <Route
        path={`${match.url}/settings`}
        component={asyncComponent(() => import("./settings"))}
      />
      {/* My Profile */}
      <Route
        path={`${match.url}/myProfile/update`}
        component={asyncComponent(() => import("../common/myProfile/edit"))}
      />
      <Route
        path={`${match.url}/myProfile/view`}
        component={asyncComponent(() => import("../common/myProfile/view"))}
      />
    </Switch>
  );
};

export default LibraryRoutes;
